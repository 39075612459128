import React, { useState, useEffect } from 'react';

const useFullWrappingCaution = () => {
  const [show, setShow] = useState(false);
  const showCaution = () => {
    setShow(true);
  };

  const hideCaution = () => {
    setShow(false);
  };

  // 限定的仕様：45度の傾斜角を保持し、高さから幅を引いた差で胴部分を表現
  const parallelogram = (ctx: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number) => {
    const width = y1 < y2 ? y2 - y1 - (x2 - x1) : x2 - x1 - (y1 - y2);
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x1, y1 + width);
    ctx.lineTo(x2, y2);
    ctx.lineTo(x2, y2 - width);
    ctx.lineTo(x1, y1);
    ctx.closePath();
    ctx.fill();
  };

  useEffect(() => {
    if (show) {
      const clientWidth = document.body.clientWidth;
      const clientHeight = document.body.clientHeight;
      const element = document.createElement('div');
      element.id = 'cautionPane';
      element.style.position = 'absolute';
      element.style.width = '100%';
      element.style.height = '100%';
      element.style.left = '0';
      element.style.top = '0';
      element.style.color = 'yellow';
      element.style.backgroundColor = 'rgba(0,0,0,0.5)';
      element.style.zIndex = '10000';
      element.style.fontSize = `${clientWidth / 14}px`;
      element.style.fontWeight = '900';
      element.style.textAlign = 'center';
      // canvasをフルサイズで取るとスクロールバーが顕在化するため、試行錯誤の結果高さだけを5%引いて解決
      element.innerHTML = `<canvas id="cautionCanvas" width="${Math.floor(clientWidth)}" \
        height="${Math.floor(clientHeight * 0.95)}"></canvas>\
        <span style="position: absolute;bottom: ${clientWidth / 20}px;\
        left: ${clientWidth / 20}px;right: ${clientWidth / 20}px;">\
        この画面を選択すると合わせ鏡現象が発生します。</span>`;
      document.body.appendChild(element);

      setTimeout(() => {
        const cautionCanvas = document.getElementById('cautionCanvas') as HTMLCanvasElement;
        if (cautionCanvas) {
          const ctx = cautionCanvas.getContext('2d');
          if (ctx) {
            ctx.fillStyle = '#ffff00';
            const fivePercent = clientWidth / 20;
            const step = fivePercent;
            const height = fivePercent + step;
            const qty = (clientHeight / (fivePercent + step)) | 0;
            let begin = step;
            for (let count = 0; count < qty; count++) {
              parallelogram(ctx, 0, begin, fivePercent, begin + height);
              parallelogram(ctx, clientWidth - fivePercent, begin + height, clientWidth, begin);
              begin += height;
            }
          }
        }
      }, 0);
    } else {
      const element = document.getElementById('cautionPane');
      if (element) {
        document.body.removeChild(element);
      }
    }
  }, [show]);

  return { showCaution, hideCaution };
};

export default useFullWrappingCaution;
