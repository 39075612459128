import React, { PropsWithChildren, memo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

interface ConfirmDialogProps {
  message: string | null;
  confirmed: () => void;
  reject: () => void;
}

function ConfirmDialog({ message, confirmed, reject }: PropsWithChildren<ConfirmDialogProps>) {
  return (
    <Dialog open={message !== null} onClose={() => reject()} fullWidth={true} maxWidth="xs">
      <DialogTitle>確認</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {(message ?? '').split('\n').map(t => (
            <>
              {t}
              <br />
            </>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => reject()} color="default" autoFocus>
          キャンセル
        </Button>
        <Button onClick={() => confirmed()} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ConfirmDialog);
