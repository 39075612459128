import React, { useState, useRef } from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useStrokeState from '../../../hooks/useStrokeState/useStrokeState';
import FileAttachmentIcon from '../../../icons/FileAttachmentIcon';
import { Media } from '@twilio/conversations';
import { StreamingMedia } from '../../ChatWindow/MessageList/MediaMessage/MediaMessage';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({
  name,
  roomName,
  setName,
  setRoomName,
  setStep,
}: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const { userNameToReconnect, roomNameToReconnect } = useStrokeState();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  // 動作確認用
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');
  const [fileData, setFileData] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileMedia, setFileMedia] = useState<any>();

  // TODO: Laravelテスト環境でトークン発行をしている。今後は、NLP_APIに持ってくる。
  async function getTokenToPhp(user_identity: any, room_name: any) {
    //const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT;
    const endpoint = process.env.REACT_APP_API_ENDPOINT + '/twilio/token'; // token取得向き先変更
    if (endpoint == undefined) {
      throw 'REACT_APP_TOKEN_ENDPOINT not set on .env ';
    }

    return fetch(endpoint, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        id: room_name,
        identity: user_identity,
      }),
    }).then(res => res.json());
  }

  const handleJoin = () => {
    console.debug(process.env.REACT_APP_ENV, 'production');
    const uName = (userNameToReconnect() ?? '') !== '' ? userNameToReconnect() : name;
    if (name != uName) setName(uName);
    const rName = (roomNameToReconnect() ?? '') !== '' ? roomNameToReconnect() : roomName;
    if (roomName != rName) setRoomName(rName);
    if (process.env.REACT_APP_ENV === 'production') {
      // PHP側で取得している。
      getTokenToPhp(uName, rName).then(result => {
        videoConnect(result.data.token.value);
        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(result.data.token.value);
      });
    } else {
      getToken(uName, rName).then(({ token }) => {
        videoConnect(token);
        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
      });
    }
  };

  const handleSendFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = event => {
        if (event?.target) {
          let base64Text = event.target.result as string;
          switch (file.type) {
            case 'application/pdf':
              base64Text = base64Text.replace('application/pdf', 'application/octet-stream');
              break;
            default:
              break;
          }
          const body = {
            contentType: file.type,
            filename: file.name,
            base64url: base64Text,
            size: file.size,
          };
          setFileName(body.filename);
          setFileData(body.base64url);
          setFileType(body.contentType);
          setFileMedia(body);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDownload = () => {
    const streamingMedia = fileMedia as StreamingMedia;
    const trueMedia = fileMedia as Media;
    if (streamingMedia?.base64url) {
      const anchorEl = document.createElement('a');

      anchorEl.download = streamingMedia.filename;
      anchorEl.href = streamingMedia.base64url;
      anchorEl.target = '_blank';
      anchorEl.rel = 'noopener';

      // setTimeout is needed in order to open files in iOS Safari.
      setTimeout(() => {
        anchorEl.click();
      });
    } else if (trueMedia?.filename) {
      trueMedia.getContentTemporaryUrl().then(url => {
        const anchorEl = document.createElement('a');

        anchorEl.download = trueMedia.filename ?? '';
        anchorEl.href = url ?? '';
        anchorEl.target = '_blank';
        anchorEl.rel = 'noopener';

        // setTimeout is needed in order to open files in iOS Safari.
        setTimeout(() => {
          anchorEl.click();
        });
      });
    }
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            入室中
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        {/* Join {roomName} */}
        {/* 空白 → */}&#x3164;{/*ㅤ ← 空白 */}
      </Typography>

      <Grid container justifyContent="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton
                id="DeviceSelectionAudioUpper"
                className={classes.mobileButton}
                disabled={disableButtons}
              />
              <ToggleVideoButton
                id="DeviceSelectionVideoUpper"
                className={classes.mobileButton}
                disabled={disableButtons}
              />
            </Hidden>
            <SettingsMenu mobileButtonClass={classes.mobileButton} />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
            <div>
              <Hidden smDown>
                <ToggleAudioButton
                  id="DeviceSelectionAudioLower"
                  className={classes.deviceButton}
                  disabled={disableButtons}
                />
                <ToggleVideoButton
                  id="DeviceSelectionVideoLower"
                  className={classes.deviceButton}
                  disabled={disableButtons}
                />
              </Hidden>
            </div>
            <div className={classes.joinButtons}>
              <Button variant="outlined" color="primary" onClick={() => setStep(Steps.finishStep)}>
                ◁ 退出
              </Button>
              <Button
                variant="contained"
                color="primary"
                data-cy-join-now
                onClick={handleJoin}
                disabled={disableButtons}
                id="button-join-now"
              >
                ▶︎ 参加
              </Button>
            </div>
          </Grid>
        </Grid>
        {process.env.REACT_APP_FILE_TEST && (
          <>
            <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleSendFile} value={''} />
            <div>
              <div>
                <Button onClick={() => fileInputRef.current?.click()}>
                  <FileAttachmentIcon />
                </Button>
                <Button onClick={() => handleDownload()}>{fileName}</Button>
                <div style={{ display: 'none' }}>{fileData}</div>
                <div style={{ display: 'none' }}>{fileType}</div>
              </div>
            </div>
          </>
        )}
      </Grid>
    </>
  );
}
