import React from 'react';
import { PALETTE, ZINDEX } from '../../constants';
import * as messages from '../../messages';
import { makeStyles } from '@material-ui/core/styles';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
//import { ColorPalette } from 'material-ui-color';
import { IconButton, Grid, Tooltip } from '@material-ui/core';

import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import {
  CanvasDrawingToolsAction,
  CanvasDrawingToolsData,
  CanvasDrawingToolsDataTool,
  CanvasToolColor,
} from '../../types';
import useStrokeState from '../../hooks/useStrokeState/useStrokeState';
import ColorSelector from '../ColorSelector/ColorSelector';

const useStyles = makeStyles(() => ({
  root: { border: '1px solid black', zIndex: ZINDEX.TOOLS, backgroundColor: 'white' },
  //groupLeft: { overflow: 'hidden', textOverflow: 'ellipsis', alignSelf: 'center', textAlign: 'right' },
  groupLeft: { alignSelf: 'center', textAlign: 'right' },
  groupCenter: { alignSelf: 'center', textAlign: 'center' },
  groupRight: { alignSelf: 'center', textAlign: 'left' },
}));

/**
 * 絵かき用のキャンバスの設定変更や動作をするためのComponent
 */
const CanvasDrawingToolsContainer = ({
  tool,
  lineColor,
  onStateChange,
  emitEvent,
}: {
  tool: CanvasDrawingToolsDataTool;
  lineColor: CanvasToolColor;
  onStateChange: (state: Partial<CanvasDrawingToolsData>) => void;
  emitEvent: (eventName: CanvasDrawingToolsAction) => void;
}): JSX.Element => {
  console.debug('COMPONENT:CanvasDrawingToolsContainer', { tool, lineColor, onStateChange, emitEvent });
  const classes = useStyles();
  // const onColorPickerChange = (value: Color) => onStateChange({ lineColor: value.hex })
  // <ColorPicker defaultValue="black" value={lineColor} onChange={onColorPickerChange} ></ColorPicker>
  //const onColorPaletteSelect = (value: string) =>
  //  onStateChange({ lineColor: PALETTE[value as keyof typeof PALETTE] as CanvasToolColor });
  //const selectedColorStyle = { border: `1px solid ${lineColor}` };
  const { undo, redo, clear, save, setMode } = useStrokeState();

  const onToolChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    onStateChange({ tool: value as CanvasDrawingToolsDataTool });
    setMode(value);
  };
  const onUndo = undo; //() => emitEvent('undo');
  const onRedo = redo; //() => emitEvent('redo');
  const onClear = clear; //() => window.confirm(messages.CLEAR_ALL_CONFIRM) && emitEvent('clear');
  const onSave = save; //() => emitEvent('save');

  return (
    <Grid container spacing={2} direction="row" className={classes.root}>
      <Grid item xs={4} className={classes.groupLeft}>
        {/*<div style={selectedColorStyle}>
          <ColorPalette palette={PALETTE} onSelect={onColorPaletteSelect} />
        </div>*/}
        <ColorSelector />
      </Grid>
      <Grid item xs={4} className={classes.groupCenter}>
        <ToggleButtonGroup exclusive value={tool} onChange={onToolChange}>
          {/*<ToggleButton value="text">{messages.TEXT_TOOL}</ToggleButton>*/}
          <ToggleButton value="line" style={{ width: '60px' }}>
            {messages.LINE_TOOL}
          </ToggleButton>
          <ToggleButton value="eraser" style={{ width: '60px' }}>
            {messages.ERASER_TOOL}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={4} className={classes.groupRight}>
        <Tooltip title={messages.UNDO}>
          <IconButton onClick={onUndo}>
            <UndoIcon></UndoIcon>
          </IconButton>
        </Tooltip>
        <Tooltip title={messages.REDO}>
          <IconButton onClick={onRedo}>
            <RedoIcon></RedoIcon>
          </IconButton>
        </Tooltip>
        <Tooltip title={messages.CLEAR}>
          <IconButton onClick={onClear}>
            <DeleteIcon></DeleteIcon>
          </IconButton>
        </Tooltip>
        <Tooltip title={messages.SAVE}>
          <IconButton onClick={onSave}>
            <SaveIcon></SaveIcon>
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default React.memo(CanvasDrawingToolsContainer);
