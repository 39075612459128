import React, { useReducer, useState, useEffect, useRef } from 'react';
import Draggable, { DraggableEventHandler, DraggableEvent, DraggableData } from 'react-draggable';
// import { ErrorBoundary } from 'react-error-boundary'
// import { Popover } from '@material-ui/core';
import CanvasDrawingToolsContainer from '../CanvasDrawingToolsContainer/index';
// import ErrorBlock from '../ErrorBlock/index'
// import useCanvasDrawingToolsData from '../../hooks/useCanvasDrawingToolsData/useCanvasDrawingToolsData';
import { emitCustomEvent } from 'react-custom-events';
import { CanvasDrawingToolsAction } from '../../types';
import { ZINDEX } from '../../constants';
import useCanvasDrawingToolsOpen from '../../hooks/useCanvasDrawingToolsOpen/useCanvasDrawingToolsOpen';
import useCanvasDrawingToolsData from '../../hooks/useCanvasDrawingToolsData/useCanvasDrawingToolsData';
import useCanvasDisabled from '../../hooks/useCanvasDisabled/useCanvasDisabled';
import useStrokeState from '../../hooks/useStrokeState/useStrokeState';
//import useDragging from "../../hooks/useDragging/useDragging";

const SimpleCanvasDrawingToolsContainer = () => {
  // POPOVER
  // const canvasToolsOpen = true // テスト用
  // const [canvasToolsOpen, setCanvasDrawingToolsOpen] = useState(false) // ローカルステート用
  // const setCanvasDrawingToolsOpen = (bool: boolean) => console.log() // テスト用
  const isDisabled = useCanvasDisabled();
  const [canvasDrawingToolsData, setCanvasDrawingToolsData] = useCanvasDrawingToolsData();
  const [isCanvasDrawingToolsOpen, setCanvasDrawingToolsOpen] = useCanvasDrawingToolsOpen();
  const handleCanvasToolsClose = () => {
    setCanvasDrawingToolsOpen(false);
  };
  const handleCanvasDrawingToolsEvent = (eventName: CanvasDrawingToolsAction) => {
    emitCustomEvent('canvas-tools-event', eventName);
  };
  // const anchorElement = useRef<HTMLDivElement>(null) // TODO: 正しく連携すること
  // const [canvasDrawingToolsData, setCanvasDrawingToolsData] = useCanvasDrawingToolsData()
  //const canvasToolsRef = useRef(null);

  const canvasToolsOpen = isCanvasDrawingToolsOpen && !isDisabled;
  console.debug('canvasToolsOpen', { canvasToolsOpen, isCanvasDrawingToolsOpen, isDisabled });

  const style = {
    display: canvasToolsOpen ? 'block' : 'none',
    pointerEvents: 'all',
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    zIndex: ZINDEX.TOOLS,
  } as const;
  // const style = { display: canvasToolsOpen ? 'block' : 'none', }

  /*
    return (
        <>
            <ErrorBoundary
                FallbackComponent={ErrorBlock}
            >
                <Popover
                    ref={canvasToolsRef}
                    open={canvasToolsOpen}
                    onClose={handleCanvasToolsClose}
                    anchorOrigin={ANCHOR_ORIGIN}
                    transformOrigin={TRANSFORM_ORIGIN}
                    anchorEl={anchorElement.current}
                    PaperProps={popOverProps}
                >
                    <Draggable nodeRef={canvasToolsRef} bounds={'parent'}>
                        <CanvasDrawingToolsContainer tool={canvasDrawingToolsData.tool} lineColor={canvasDrawingToolsData.lineColor} onStateChange={setCanvasDrawingToolsData} emitEvent={handleCanvasDrawingToolsEvent}></CanvasDrawingToolsContainer>
                    </Draggable>
                </Popover>
            </ErrorBoundary>

            <div ref={anchorElement}></div>
        </>
    )
    */
  const [boundRect, setBoundRect] = useState<{ left?: number; top?: number; right?: number; bottom?: number }>({});
  const { addResizeListener, removeResizeListener } = useStrokeState();
  const draggableRef = useRef(null);

  // 初期化
  useEffect(() => {
    addResizeListener(onResize);
    return () => removeResizeListener(onResize);
  });

  // ブラウザーサイズが変更されたとき
  const onResize = (resized: { left: number; top: number; right: number; bottom: number }) => {
    const draggablePane = document.getElementById('draggablePane');
    if (draggablePane) {
      const rect = draggablePane.getBoundingClientRect();
      const transform = draggablePane.style.transform; // translate(0px, 0px)
      const work = {
        left: 0,
        top: rect.height - resized.bottom,
        right: resized.right - rect.width,
        bottom: 0,
      };
      //setBoundRect(work);
      if (transform) {
        //const body = document.getElementsByTagName('body')[0];
        const transforms = transform
          .split('(')[1]
          .split(')')[0]
          .split(',');
        //const positions = transform.props.position
        //const offsetParent = draggablePane.off
        const posX = parseInt(transforms[0]);
        const posY = parseInt(transforms[1]);
        // 枠外にはみ出した場合 - 以下の方法は draggablePane.style.left が常に0（自分相対）なのでNG
        if (resized.right - rect.width < posX) {
          draggablePane.style.transform = `translate(${resized.right - rect.width}px, ${posY}px)`;
        }
        if (posY < rect.height - resized.bottom) {
          draggablePane.style.transform = `translate(${posX}px, ${rect.height - resized.bottom}px)`;
        }
      }
      setBoundRect(work);
    }
  };

  /*const onDrag: DraggableEventHandler = (e: DraggableEvent, data: DraggableData) => {
    e;
    data;
    return false;
  };*/

  // Draggable
  // https://github.com/react-grid-layout/react-draggable#draggable-props
  // const BOUNDS = '#canvasWrapper' // 本来これがよい。Componentを表示する場所を変えれば多分OKになる。
  const BOUNDS = { left: -9999, top: -9999, right: 9999, bottom: 9999 }; // BOUNDSなし
  return (
    <>
      {/*<Draggable
        nodeRef={canvasToolsRef}
        bounds={BOUNDS}
        offsetParent={document.querySelector('#canvasWrapper') as HTMLElement}
      >*/}
      <Draggable
        bounds={boundRect}
        ref={draggableRef}
        onMouseDown={(e: any) => {
          // touchの時のみ作用するように
          if (e.touches) {
            e.preventDefault();
            console.debug('');
            const touch = e.touches[0];
            // SVG利用のアイコンボタン
            if (touch.target.tagName === 'path') {
              // アイコンボタンはアイコンそのものにonclickを送ると例外が発生するので
              // 改めて親をclickする
              const svg = touch.target.parentNode;
              const span = svg.parentNode;
              const button = span.parentNode;
              button.click();
            } else if (touch.target.tagName === 'svg') {
              const span = touch.target.parentNode;
              const button = span.parentNode;
              button.click();
            } else if (touch.target.tagName === 'span') {
              const button = touch.target.parentNode;
              button.click();
            } else {
              touch.target.click();
            }
          }
        }}
      >
        <div style={style} id="draggablePane">
          <CanvasDrawingToolsContainer
            tool={canvasDrawingToolsData.tool}
            lineColor={canvasDrawingToolsData.lineColor}
            onStateChange={setCanvasDrawingToolsData}
            emitEvent={handleCanvasDrawingToolsEvent}
          ></CanvasDrawingToolsContainer>
        </div>
      </Draggable>
    </>
  );

  // OVERLAY
  /*
    return (
        <div style={style}>
            <CanvasDrawingToolsContainer
                tool={canvasDrawingToolsData.tool}
                lineColor={canvasDrawingToolsData.lineColor}
                onStateChange={setCanvasDrawingToolsData}
                emitEvent={handleCanvasDrawingToolsEvent}
            ></CanvasDrawingToolsContainer>
        </div>
    );
    */

  // 標準
  /*
    return (
        <CanvasDrawingToolsContainer tool={canvasDrawingToolsData.tool} lineColor={canvasDrawingToolsData.lineColor} onStateChange={setCanvasDrawingToolsData} emitEvent={handleCanvasDrawingToolsEvent}></CanvasDrawingToolsContainer>
    );
    */
};

export default SimpleCanvasDrawingToolsContainer;
