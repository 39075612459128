import { useEffect, MutableRefObject } from 'react';

const UseResizeObserver = (elements: MutableRefObject<null>[], callback: (entries: ResizeObserverEntry[]) => void) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      callback(entries);
    });

    elements.forEach(element => {
      if (element?.current) resizeObserver.observe((element.current as unknown) as HTMLElement);
    });

    return () => resizeObserver.disconnect();
  }, [callback, elements]);
};

export default UseResizeObserver;
