import React, {
  useState,
  FC,
  useCallback,
  MouseEventHandler,
  TouchEventHandler,
  CSSProperties,
  useEffect,
  useRef,
} from 'react';
import { useStrokeState } from '../../hooks/useStrokeState/useStrokeState';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { LocalDataTrack } from 'twilio-video';
import { PlotEvent } from '../../types';

export type CanvasContainerAttribute = {
  className?: string;
  style?: CSSProperties;
  width: number;
  height: number;
  lineWidth?: number;
  //lineColor?: string;
  //lineCap?: CanvasLineCap;
  clear?: boolean;
  onUpdateCanvas?: (e?: HTMLCanvasElement) => void;
  //onMouseEvent?: (e: MouseEvent) => void
  id: string;
};

const CanvasContainer: FC<CanvasContainerAttribute> = props => {
  const [drawing, setDrawing] = useState(false);
  const { whoIs } = useAppState();
  const {
    addPlotEvent,
    getDrawMode,
    offsetX,
    offsetY,
    uuid,
    strokeColor,
    upperBias,
    addBroadcastListener,
    removeBroadcastListener,
    setAckJoinned,
    gotHereUR,
    setSendData,
    getPlotEventListeners,
  } = useStrokeState();
  const { localTracks, isSharingScreen } = useVideoContext();
  const [dataTrack, setDataTrack] = useState<LocalDataTrack | undefined>(undefined);
  const [initialized, setInitialized] = useState(false);

  const returnStroke = (stroke: PlotEvent[]) => {
    const temp = localTracks.filter(t => t.name === 'data')[0] as LocalDataTrack | undefined;
    if (temp && stroke) {
      const data = JSON.stringify(stroke);
      temp.send(data);
    }
  };
  const localDataTrack = localTracks.find(track => track.kind === 'data') as LocalDataTrack | undefined;

  // 初期化
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setSendData(returnStroke);
      // 共有親の場合
      if (isSharingScreen) {
        const temp = localTracks.filter(t => t.name === 'data')[0] as LocalDataTrack | undefined;
        setDataTrack(temp);
      } else {
        let count = 0;
        const handle = setInterval(() => {
          if (count++ === 10) {
            // 10回呼んだらやめる
            clearTimeout(handle);
          } else {
            if (localDataTrack) {
              const temp = localTracks.filter(t => t.name === 'data')[0] as LocalDataTrack | undefined;
              if (temp) {
                setDataTrack(temp);
                const requests: PlotEvent[] = [
                  {
                    userName: whoIs ?? '',
                    id: '',
                    action: 'joinned',
                  },
                ];
                const message = JSON.stringify(requests);
                let count2 = 0;
                const handle2 = setInterval(() => {
                  if (count2++ === 10) {
                    // 10回呼んだらやめる
                    clearTimeout(handle2);
                  } else {
                    if (getPlotEventListeners().length !== 0) {
                      try {
                        temp.send(message);
                        setAckJoinned(true);
                        //clearTimeout(handle);
                      } catch (e) {
                        console.log(e.toString());
                      } finally {
                        clearTimeout(handle2);
                      }
                    }
                  }
                }, 1000);
              }
              if (gotHereUR) {
                clearTimeout(handle);
              }
            }
          }
        }, 1000);
      }
    }
  }, []);

  /*useEffect(() => {
    //
    console.debug('message has been changed.');
  }, [message]);*/

  /*useEffect(() => {
    setDataTrack(localTracks.filter(t => t.name === 'data')[0] as LocalDataTrack | undefined);
  }, [localTracks]);*/

  const broadcast = useCallback(
    (plotEvents: PlotEvent[]) => {
      if (dataTrack) {
        const message = JSON.stringify(plotEvents);
        dataTrack.send(message);
      }
    },
    [dataTrack]
  );

  const ref = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const current = ref.current;
    if (!current) return;
    const onTouchStart = (event: Event) => event.preventDefault();
    current.addEventListener('touchstart', onTouchStart);
    addBroadcastListener(broadcast);
    return () => {
      current.removeEventListener('touchstart', onTouchStart);
      removeBroadcastListener(broadcast);
    };
  }, [addBroadcastListener, broadcast, removeBroadcastListener]);

  // 開始ポイント
  const mouseDown: MouseEventHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    const { offsetX: x, offsetY: y } = e.nativeEvent;
    setDrawing(true);
    const plotEvent = {
      id: uuid(),
      userName: whoIs ?? '',
      action: 'begin',
      x: x - offsetX,
      y: y - offsetY - upperBias,
      targetWidth: props.width - offsetX * 2,
      targetHeight: props.height - offsetY * 2,
      drawMode: getDrawMode(),
      lineWidth: props.lineWidth ?? 1,
      lineColor: strokeColor ?? 'black',
    };
    addPlotEvent([plotEvent]);
    if (dataTrack) {
      const message = JSON.stringify(plotEvent);
      dataTrack.send(message);
    }
    //console.debug(`offsetX:${offsetX}, offsetY:${offsetY}, props.width:${props.width}, props.height:${props.height}`);
  };

  // 追従ポイント
  const mouseMove: MouseEventHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    if (drawing) {
      const { offsetX: x, offsetY: y } = e.nativeEvent;
      const plotEvent = {
        id: uuid(),
        userName: whoIs ?? '',
        action: 'continue',
        x: x - offsetX,
        y: y - offsetY - upperBias,
        targetWidth: props.width - offsetX * 2,
        targetHeight: props.height - offsetY * 2,
        drawMode: getDrawMode(),
        lineWidth: props.lineWidth ?? 1,
        lineColor: strokeColor ?? 'black',
      };
      addPlotEvent([plotEvent]);
      if (dataTrack) {
        const message = JSON.stringify(plotEvent);
        dataTrack.send(message);
      }
    }
  };

  // 線描画完了
  const endDrawing = () => {
    if (drawing) {
      setDrawing(false);
      const plotEvent = { id: uuid(), userName: whoIs ?? '', action: 'end' };
      addPlotEvent([plotEvent]);
      if (dataTrack) {
        const message = JSON.stringify(plotEvent);
        dataTrack.send(message);
      }
    }
  };

  // 描画一時停止 - 領域外に出た場合
  /*const pauseDrawing = (e: any) => {
    if (drawing) {
      const { offsetX: x ,offsetY: y } = e.nativeEvent;
      const ctx = getContext();
      if (ctx) {
        ctx.lineTo(x, y);
        ctx.stroke();
      }
      setPause(true);
    }
  }*/

  // 開始ポイント
  const touchStart: TouchEventHandler = (e: React.TouchEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { clientX: x, clientY: y } = e.nativeEvent.touches[0];
    setDrawing(true);
    const plotEvent = {
      id: uuid(),
      userName: whoIs ?? '',
      action: 'begin',
      x: x - offsetX,
      y: y - offsetY - upperBias,
      targetWidth: props.width - offsetX * 2,
      targetHeight: props.height - offsetY * 2,
      drawMode: getDrawMode(),
      lineWidth: props.lineWidth ?? 1,
      lineColor: strokeColor ?? 'black',
    };
    addPlotEvent([plotEvent]);
    if (dataTrack) {
      const message = JSON.stringify(plotEvent);
      dataTrack.send(message);
    }
  };

  // 追従ポイント
  const touchMove: TouchEventHandler = (e: React.TouchEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (drawing) {
      const { clientX: x, clientY: y } = e.nativeEvent.touches[0];
      const plotEvent = {
        id: uuid(),
        userName: whoIs ?? '',
        action: 'continue',
        x: x - offsetX,
        y: y - offsetY - upperBias,
        targetWidth: props.width - offsetX * 2,
        targetHeight: props.height - offsetY * 2,
        drawMode: getDrawMode(),
        lineWidth: props.lineWidth ?? 1,
        lineColor: strokeColor ?? 'black',
      };
      addPlotEvent([plotEvent]);
      if (dataTrack) {
        const message = JSON.stringify(plotEvent);
        dataTrack.send(message);
      }
    }
  };

  // 線描画完了
  const touchEnd: TouchEventHandler = (e: React.TouchEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (drawing) {
      setDrawing(false);
      const plotEvent = { id: uuid(), userName: whoIs ?? '', action: 'end' };
      addPlotEvent([plotEvent]);
      if (dataTrack) {
        const message = JSON.stringify(plotEvent);
        dataTrack.send(message);
      }
    }
  };

  // canvasはマウスイベントを回収するためのみに利用
  return (
    <div className={props.className} style={{ ...props.style, cursor: 'crosshair' }} id={props.id}>
      <canvas
        ref={ref}
        width={props.width}
        height={props.height}
        onMouseDown={mouseDown}
        onMouseMove={mouseMove}
        onMouseUp={endDrawing}
        onMouseLeave={endDrawing}
        onTouchStart={touchStart}
        onTouchMove={touchMove}
        onTouchEnd={touchEnd}
      />
    </div>
  );
};

export default React.memo(CanvasContainer);
