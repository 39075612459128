import React, { useState, useEffect, useRef, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useStrokeState from '../../hooks/useStrokeState/useStrokeState';
import ColorPicker from '../ColorPicker/ColorPicker';

const useStyles = makeStyles(() => ({
  frame: {
    width: '60px',
    height: '60px',
    border: '1px solid rgba(0, 0, 0, 0.18)',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    borderRadius: '4px',
    margin: 'auto',
  },
  buttonOuter: {
    width: '45px',
    height: '45px',
    cursor: 'pointer',
    border: '4px solid white',
    borderRadius: '4px',
    filter: 'drop-shadow(0px 2.5px 5px rgba(0, 0, 0, 0.13))',
    margin: 'auto',
    marginTop: '7.5px',
    backgroundColor: 'white',
  },
  buttonInner: {
    width: '37px',
    height: '37px',
    cursor: 'pointer',
    border: '0px solid white',
    borderRadius: '4px',
    margin: 'auto',
  },
  colorPickerPane: {
    marginLeft: '-42px',
    marginTop: '-122px',
    backgroundColor: 'white',
    display: 'table',
    borderRadius: '4px',
  },
}));

const ColorSelector = () => {
  const classes = useStyles();
  const { strokeColor } = useStrokeState();
  const [colLine, setColLine] = useState('');
  const [colorPickerDisabled, setColorPickerDisabled] = useState(true);
  const buttonOuter = useRef<HTMLDivElement>(null);
  const colorPickerPane = useRef<HTMLDivElement>(null);

  // 初期化
  useEffect(() => {
    setColor(strokeColor);
  });

  // 色が変わった場合
  useEffect(() => {
    const button = document.getElementById('colorSelectorColor');
    if (button) button.style.backgroundColor = colLine;
  }, [colLine]);

  // colorPickerを表示/非表示したい
  useEffect(() => {
    //
  }, [colorPickerDisabled]);

  const onClick = () => {
    colorPickerDisabled ? setColorPickerDisabled(false) : setColorPickerDisabled(true);
  };

  const setColor = (color: string) => {
    if (colLine !== color) {
      setColLine(color);
      setTimeout(() => setColorPickerDisabled(true), 333);
    }
  };

  return (
    <div className={classes.frame}>
      <div className={classes.buttonOuter} ref={buttonOuter}>
        {colorPickerDisabled ? (
          <></>
        ) : (
          <div
            className={classes.colorPickerPane}
            ref={colorPickerPane}
            onTouchStart={e => {
              e.stopPropagation();
            }}
          >
            <ColorPicker selectedColor={colLine} onChanged={setColor} />
          </div>
        )}
        <div
          className={classes.buttonInner}
          id="colorSelectorColor"
          style={{ backgroundColor: colLine }}
          onClick={onClick}
        ></div>
      </div>
    </div>
  );
};

export default ColorSelector;
