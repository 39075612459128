import { useState } from 'react';
import { PlotEvent, DrawMode } from '../../types';
import { useAppState } from '../../state';
//import { Canvas } from 'fabric/fabric-impl';
//import useVideoContext from '../useVideoContext/useVideoContext';

// 共用できそうで出来ないのでMirroringContainerから
// 描画系関数を持ち込んでフィッティングしています。
type Point = {
  x: number;
  y: number;
};

const plotEventListeners: ((plotEvent: PlotEvent) => void)[] = [];
const strokeListeners: ((plotEvent: PlotEvent[]) => void)[] = [];
const broadcastListeners: ((plotEvent: PlotEvent[]) => void)[] = [];
const stroke: PlotEvent[] = [];
//let roomIs = '';
let drawMode: DrawMode = 'pen';
const undidStrokes = new Map<string, boolean>();
const strokeIndices = new Map<string, number>();
let offsetX = 0;
let offsetY = 0;
let screenScale = 1;
let strokeColor = '#0030ff';
let realWidth = 0;
let realHeight = 0;
let upperBias = 0;
let bodyBound: { left: number; top: number; right: number; bottom: number } = {
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
};
const resizeListeners: ((resized: { left: number; top: number; right: number; bottom: number }) => void)[] = [];
const switchCanvasListeners: ((show: boolean) => void)[] = [];
let sendData: ((stroke: PlotEvent[]) => void) | null = null;
let ackJoinned = false;
let gotHereUR = false;
let sharingMaster = false;
const lastPlotPoints = new Map<string, Point>();

export const useStrokeState = () => {
  const { whoIs, setConfirmMessage, setInformation } = useAppState();
  //const { isSharingScreen } = useVideoContext();

  const addPlotEvent = (plotEvents: PlotEvent[]) => {
    const qty = plotEvents.length ?? 0;
    if (0 < qty) {
      // idがない場合は制御コマンド
      if (plotEvents[0].id === '') {
        if (plotEvents[0].userName !== whoIs) {
          switch (plotEvents[0].action) {
            case 'undo':
              undoParticipant(plotEvents);
              break;
            case 'redo':
              redoParticipant(plotEvents);
              break;
            case 'joinned': {
              // 共有親であれば
              //if (sharingMaster) { このセット時に参照した共有主フラグはタイミング的にいつでも真になる前だった
              const stopSharingScreen = document.getElementById('stop-sharing-screen');
              if (stopSharingScreen) {
                // 全データ送信
                sendStroke(plotEvents[0].userName);
              }
              break;
            }
            case 'allclear':
              stroke.splice(0);
              provideToStrokeListeners();
              break;
            default:
              throw 'Caught invalid command!';
          }
        } else if (plotEvents[0].userName === whoIs) {
          switch (plotEvents[0].action) {
            case 'hereur':
              if (ackJoinned) {
                setAckJoinned(false);
                setGotHereUR(true);
                stroke.splice(0);
                strokeIndices.clear();
                plotEvents.splice(0, 1);
                plotEvents.forEach((plot, index) => {
                  stroke.push(plot);
                  strokeIndices.set(plot.id, index);
                });
                provideToStrokeListeners();
              }
              break;
            default:
              throw 'Caught invalid command!';
          }
        }
      } else {
        if (undidStrokes.get(plotEvents[0].userName)) {
          undidStrokes.set(plotEvents[0].userName, false);
          removeUndidStroke();
        }
        stroke.push(plotEvents[0]);
        strokeIndices.set(plotEvents[0].id, stroke.length - 1);
        if ((whoIs ?? '') !== '') {
          plotEventListeners.forEach(listener => {
            try {
              listener(plotEvents[0]);
            } catch (e) {
              throw 'The listener should be released!';
            }
          });
        }
      }
    }
  };

  const undo = () =>
    setTimeout(() => {
      const toBroadcast: PlotEvent[] = [];
      toBroadcast.push({ id: '', userName: whoIs ?? '', action: 'undo' });
      // 操作者のundidフラグのない末尾連結要素先頭を抽出
      let hereItIs = stroke.length;
      for (let index = stroke.length - 1; 0 <= index; index--) {
        if (!(stroke[index].undid ?? false) && stroke[index].userName === whoIs) {
          if (stroke[index].action === 'begin') {
            hereItIs = index;
            break;
          }
        }
      }
      // 次の同操作者の連結要素末尾までをundid
      let initialFound = false;
      for (let target = hereItIs; target < stroke.length; target++) {
        if (stroke[target].userName === whoIs) {
          if (stroke[target].action === 'begin') {
            if (!initialFound) {
              initialFound = true;
              stroke[target].undid = true;
              toBroadcast.push(stroke[target]);
            } else {
              break;
            }
          } else {
            stroke[target].undid = true;
            toBroadcast.push(stroke[target]);
          }
        }
      }
      // undoしましたよ、次の新規プロットはundid情報を削除して継ぎ足してね
      undidStrokes.set(whoIs ?? '', true);
      provideToStrokeListeners();
      provideBroadcastListeners(toBroadcast);
    }, 0);

  const redo = () =>
    setTimeout(() => {
      const toBroadcast: PlotEvent[] = [];
      toBroadcast.push({ id: '', userName: whoIs ?? '', action: 'redo' });
      // 操作者のundidフラグのある先頭連結要素先頭を抽出
      let hereItIs = stroke.length;
      for (let index = 0; index < stroke.length; index++) {
        if ((stroke[index].undid ?? false) && stroke[index].userName === whoIs) {
          if (stroke[index].action === 'begin') {
            hereItIs = index;
            break;
          }
        }
      }
      // 次の同操作者の連結要素末尾までをundid解除
      let initialFound = false;
      for (let target = hereItIs; target < stroke.length; target++) {
        if (stroke[target].userName === whoIs) {
          if (stroke[target].action === 'begin') {
            if (!initialFound) {
              initialFound = true;
              stroke[target].undid = false;
              toBroadcast.push(stroke[target]);
            } else {
              break;
            }
          } else {
            stroke[target].undid = false;
            toBroadcast.push(stroke[target]);
          }
        }
      }
      provideToStrokeListeners();
      provideBroadcastListeners(toBroadcast);
    }, 0);

  const clear = () =>
    setTimeout(() => {
      if (stroke.length === 0) {
        //setShowOkay(true);
      } else {
        //if (sharingMaster) { このセット時に参照した共有主フラグはタイミング的にいつでも真になる前だった
        const stopSharingScreen = document.getElementById('stop-sharing-screen');
        if (stopSharingScreen) {
          setConfirmMessage('描画と履歴を全て消去します。\nよろしいですか？');
        } else {
          setInformation(`画面共有発信者のみ消去できます。`);
        }
      }
    }, 0);

  const execClear = () => {
    setConfirmMessage(null);
    stroke.splice(0);
    strokeIndices.clear();
    provideToStrokeListeners();
    const requests: PlotEvent[] = [
      {
        userName: whoIs ?? '',
        id: '',
        action: 'allclear',
      },
    ];
    provideBroadcastListeners(requests);
  };

  const reject = () => {
    setConfirmMessage(null);
  };

  const modifyContext = (
    ctx: CanvasRenderingContext2D,
    paramScreenScale: number,
    lineWidth?: number,
    lineCap?: CanvasLineCap,
    lineColor?: string,
    drawMode?: DrawMode
  ) => {
    if (drawMode === 'pen') {
      ctx.globalCompositeOperation = 'source-over';
      ctx.lineWidth = lineWidth ?? 1;
      ctx.lineCap = lineCap ?? 'round';
      ctx.strokeStyle = lineColor ?? 'black';
    } else if (drawMode === 'eraser') {
      ctx.globalCompositeOperation = 'destination-out';
      ctx.lineWidth = 20 * paramScreenScale;
      ctx.lineCap = 'round';
    }
  };

  const beginStroke = (
    ctx: CanvasRenderingContext2D,
    paramScreenScale: number,
    width: number,
    height: number,
    e: PlotEvent
  ) => {
    const { x, y, lineWidth, lineCap, lineColor, targetWidth, targetHeight, drawMode } = e;
    const ratioW = width / (targetWidth ?? width);
    const ratioH = height / (targetHeight ?? height);
    const ratio = ratioH < ratioH ? ratioW : ratioH;
    const lineWidthScaled = (lineWidth ?? 5) * paramScreenScale;
    modifyContext(ctx, paramScreenScale, lineWidthScaled, lineCap, lineColor, drawMode);
    const realX = (x ?? 1) * ratio;
    const realY = (y ?? 1) * ratio;
    if (ctx && realX && realY) {
      ctx.beginPath();
      ctx.moveTo(realX, realY);
      lastPlotPoints.set(e.userName, { x: realX, y: realY });
    }
  };

  const continueStroke = (
    ctx: CanvasRenderingContext2D,
    paramScreenScale: number,
    width: number,
    height: number,
    e: PlotEvent
  ) => {
    const { x, y, lineWidth, lineCap, lineColor, targetWidth, targetHeight, drawMode } = e;
    const ratioW = width / (targetWidth ?? width);
    const ratioH = height / (targetHeight ?? height);
    const ratio = ratioW < ratioH ? ratioW : ratioH;
    const lineWidthScaled = (lineWidth ?? 5) * paramScreenScale;
    modifyContext(ctx, paramScreenScale, lineWidthScaled, lineCap, lineColor, drawMode);
    const realX = (x ?? 1) * ratio;
    const realY = (y ?? 1) * ratio;
    if (ctx && realX && realY) {
      if (lastPlotPoints.has(e.userName)) {
        const point = lastPlotPoints.get(e.userName);
        if (point?.x && point?.y) {
          ctx.beginPath();
          ctx.strokeStyle = lineColor ?? '#000000'; // beginPath後の指定でMacのSafariへの効果を期待
          ctx.moveTo(point.x, point.y);
        }
      }
      ctx.lineTo(realX, realY);
      lastPlotPoints.set(e.userName, { x: realX, y: realY });
      ctx.stroke();
    }
    //console.debug(`elementWidth:${targetWidth}, elementHeight:${targetHeight}, props.width:${props.width}, props.height:${props.height}`);
    //console.debug(`ratioW:${ratioW}, ratioH:${ratioH}, ratio:${ratio}`);
  };

  const drawStroke = (ctx: CanvasRenderingContext2D, width: number, height: number) => {
    const paramScreenScale = width / (realWidth ?? 1);
    for (const event of stroke) {
      if (!(event.undid ?? false)) {
        switch (event.action) {
          case 'begin':
            beginStroke(ctx, paramScreenScale, width, height, event);
            break;
          case 'continue':
            continueStroke(ctx, paramScreenScale, width, height, event);
            break;
          case 'end':
            //endDrawing();
            break;
          default:
            break;
        }
      }
    }
  };
  interface VideoElement {
    videoWidth: number;
    videoHeight: number;
  }
  const captureVideoFrame = (video?: VideoElement | Element) => {
    if (video as VideoElement) {
      const canvas = document.createElement('canvas');
      const canvasDraw = document.createElement('canvas');

      canvas.width = (video as VideoElement).videoWidth;
      canvas.height = (video as VideoElement).videoHeight;
      canvasDraw.width = (video as VideoElement).videoWidth;
      canvasDraw.height = (video as VideoElement).videoHeight;

      const ctx = canvas.getContext('2d');
      const ctxDraw = canvasDraw.getContext('2d');
      if (ctx && ctxDraw && (video as Element)) {
        // 共有画面を取得
        ctx.drawImage(video as CanvasImageSource, 0, 0);

        // 全描画を描画
        //ctxDraw.clearRect(0, 0, canvas.width, canvas.height);
        //ctxDraw.fillStyle = 'rgba(0,0,0,0)';
        //ctxDraw.fillRect(0, 0, canvas.width, canvas.height)
        drawStroke(ctxDraw, canvas.width, canvas.height);

        // マージ
        mergeCanvas(ctx, ctxDraw, canvas.width, canvas.height);

        const dataUri = canvas.toDataURL('image/jpeg', 0.92);
        const data = dataUri.split(',')[1];
        const mimeType = dataUri.split(';')[0].slice(5);

        const bytes = window.atob(data);
        const buf = new ArrayBuffer(bytes.length);
        const arr = new Uint8Array(buf);

        for (let i = 0; i < bytes.length; i++) {
          arr[i] = bytes.charCodeAt(i);
        }

        const blob = new Blob([arr], { type: mimeType });
        return { blob, dataUri };
      } else {
        const blob = new Blob();
        const dataUri = '';
        return { blob, dataUri };
      }
    } else {
      const blob = new Blob();
      const dataUri = '';
      return { blob, dataUri };
    }
  };

  const mergeCanvas = async (
    shared: CanvasRenderingContext2D,
    drawed: CanvasRenderingContext2D,
    width: number,
    height: number
  ) => {
    const resultCtx = shared;
    if (resultCtx) {
      const mainCtx = shared;
      const maskCtx = drawed;
      //メインとマスクのイメージデータを取得
      const mainImgData = mainCtx.getImageData(0, 0, width, height);
      const maskImgData = maskCtx.getImageData(0, 0, width, height);
      const mainData = mainImgData.data;
      const maskData = maskImgData.data;

      //各ピクセルに対して繰り返し
      for (let index = 0, qty = width * height; index < qty; index++) {
        //ピクセル
        const ptr = index * 4;
        //RGB値はメインをそのまま
        if ((maskData[ptr] | maskData[ptr + 1] | maskData[ptr + 2] | maskData[ptr + 3]) !== 0) {
          mainData[ptr] = maskData[ptr];
          mainData[ptr + 1] = maskData[ptr + 1];
          mainData[ptr + 2] = maskData[ptr + 2];
          mainData[ptr + 3] = maskData[ptr + 3];
        }
      }

      //Canvasに戻す
      mainCtx.putImageData(mainImgData, 0, 0);
    }
  };

  const saveBlob = (blob: Blob, filename: string) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    //a.style = 'display: none';

    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const save = () =>
    setTimeout(() => {
      const videoEl = document.querySelector('[data-cy-main-participant] video');
      if (videoEl) {
        const { blob, dataUri } = captureVideoFrame(videoEl);
        saveBlob(blob, 'shapshot.jpg');
      }
    }, 0);

  const provideToStrokeListeners = (data?: PlotEvent[]) => {
    if ((whoIs ?? '') !== '') {
      strokeListeners.forEach(listener => {
        try {
          listener(data ?? stroke);
        } catch (e) {
          throw 'The listener should be released!';
        }
      });
    }
  };

  const setMode = (mode: string) => {
    if (mode === 'line') {
      drawMode = 'pen';
    } else if (mode === 'eraser') {
      drawMode = 'eraser';
    }
  };

  const getDrawMode = () => drawMode;

  const addPlotEventListener = (listener: (plotEvent: PlotEvent) => void): void => {
    // 二重登録がないように
    const index = plotEventListeners.indexOf(listener);
    if (index !== -1) {
      plotEventListeners.splice(index, 1);
      throw 'duplicated!';
    }
    plotEventListeners.push(listener);
  };

  const removePlotEventListener = (listener: (plotEvent: PlotEvent) => void): void => {
    const index = plotEventListeners.indexOf(listener);
    if (index !== -1) {
      plotEventListeners.splice(index, 1);
    }
  };

  const addStrokeListener = (listener: (plotEvent: PlotEvent[]) => void): void => {
    // 二重登録がないように
    const index = strokeListeners.indexOf(listener);
    if (index !== -1) {
      strokeListeners.splice(index, 1);
      throw 'duplicated!';
    }
    strokeListeners.push(listener);
  };

  const removeStrokeListener = (listener: (plotEvent: PlotEvent[]) => void): void => {
    const index = strokeListeners.indexOf(listener);
    if (index !== -1) {
      strokeListeners.splice(index, 1);
    }
  };

  const removeUndidStroke = () => {
    // 操作者のundidフラグのある情報以外を収集
    const work = Array.from(stroke);
    stroke.splice(0);
    strokeIndices.clear();
    for (let index = 0; index < work.length; index++) {
      if ((work[index].undid ?? false) && work[index].userName === whoIs) {
        // 操作者のundidフラグのある情報には何もしない
      } else {
        stroke.push(work[index]);
        strokeIndices.set(work[index].id, index);
      }
    }
  };

  const resized = (listener: (plotEvent: PlotEvent[]) => void) => {
    listener(stroke);
  };

  const setOffsetX = (val: number) => (offsetX = val);
  const setOffsetY = (val: number) => (offsetY = val);

  const setScreenScale = (val: number) => {
    screenScale = val;
  };

  const uuid = () => {
    let d = new Date().getTime(); //Timestamp
    let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  };

  const setStrokeColor = (val: string) => (strokeColor = val);

  const setRealWidth = (val: number) => (realWidth = val);
  const setRealHeight = (val: number) => (realHeight = val);

  const setUpperBias = (val: number) => (upperBias = val);

  const setBodyBound = (val: { left: number; top: number; right: number; bottom: number }) => {
    bodyBound = val;
    provideToResizeListeners();
  };

  const addResizeListener = (
    listener: (resized: { left: number; top: number; right: number; bottom: number }) => void
  ): void => {
    // 二重登録がないように
    const index = resizeListeners.indexOf(listener);
    if (index !== -1) {
      resizeListeners.splice(index, 1);
      throw 'duplicated!';
    }
    resizeListeners.push(listener);
  };

  const removeResizeListener = (
    listener: (resized: { left: number; top: number; right: number; bottom: number }) => void
  ): void => {
    const index = resizeListeners.indexOf(listener);
    if (index !== -1) {
      resizeListeners.splice(index, 1);
    }
  };

  const provideToResizeListeners = () => {
    if ((whoIs ?? '') !== '') {
      resizeListeners.forEach(listener => {
        try {
          listener(bodyBound);
        } catch (e) {
          throw 'The listener should be released!';
        }
      });
    }
  };

  const [isShowCanvas, _setShowCanvas] = useState(false);
  const setShowCanvas = (val: boolean) => {
    if (isShowCanvas !== val) {
      _setShowCanvas(val);
      provideSwitchCanvasListeners(val);
    }
  };
  const addSwitchCanvasListener = (listener: (show: boolean) => void) => {
    // 二重登録がないように
    const index = switchCanvasListeners.indexOf(listener);
    if (index !== -1) {
      switchCanvasListeners.splice(index, 1);
      throw 'duplicated!';
    }
    switchCanvasListeners.push(listener);
  };

  const removeSwitchCanvasListener = (listener: (show: boolean) => void) => {
    const index = switchCanvasListeners.indexOf(listener);
    if (index !== -1) {
      switchCanvasListeners.splice(index, 1);
    }
  };

  const provideSwitchCanvasListeners = (val: boolean) => {
    if ((whoIs ?? '') !== '') {
      switchCanvasListeners.forEach(listener => {
        try {
          listener(val);
        } catch (e) {
          throw 'The listener should be released!';
        }
      });
    }
  };

  // 1インスタンスにつき一つのリスナー構成になる
  const addBroadcastListener = (listener: (toBroadcast: PlotEvent[]) => void) => {
    // 二重登録がないように - 一つしかないが、それでも二重はだめ
    const index = broadcastListeners.indexOf(listener);
    if (index !== -1) {
      broadcastListeners.splice(index, 1);
      throw 'duplicated!';
    }
    broadcastListeners.push(listener);
  };

  const removeBroadcastListener = (listener: (toBroadcast: PlotEvent[]) => void) => {
    const index = broadcastListeners.indexOf(listener);
    if (index !== -1) {
      broadcastListeners.splice(index, 1);
    }
  };

  const provideBroadcastListeners = (toBroadcast: PlotEvent[]) => {
    if (0 < toBroadcast.length) {
      if ((whoIs ?? '') !== '') {
        broadcastListeners.forEach(listener => {
          try {
            listener(toBroadcast);
          } catch (e) {
            throw 'The listener should be released!';
          }
        });
      }
    }
  };

  const undoParticipant = (plotEvents: PlotEvent[]) => {
    const qty = plotEvents.length;
    // 先頭はコマンド情報
    for (let offset = 1; offset < qty; offset++) {
      const id = plotEvents[offset].id;
      if (strokeIndices.has(id)) {
        const index = strokeIndices.get(id);
        if (index) {
          stroke[index].undid = true;
        }
      } else {
        //throw 'Something wrong about stroke';
      }
    }
    undidStrokes.set(whoIs ?? '', true);
    provideToStrokeListeners();
  };

  const redoParticipant = (plotEvents: PlotEvent[]) => {
    const qty = plotEvents.length;
    for (let offset = 1; offset < qty; offset++) {
      const id = plotEvents[offset].id;
      if (strokeIndices.has(id)) {
        const index = strokeIndices.get(id);
        if (index) {
          stroke[index].undid = false;
        }
      } else {
        //throw 'Something wrong about stroke';
      }
    }
    undidStrokes.set(whoIs ?? '', true);
    provideToStrokeListeners();
  };

  /*type PlotEvents = {
    array: PlotEvent[]
  };*/
  const sendStroke = (requester: string) => {
    const queue: PlotEvent[] = [];
    const infoTag: PlotEvent = {
      id: '',
      userName: requester,
      action: 'hereur',
    };
    /*const continue: PlotEvent = {
      id: '',
      userName: whoIs ?? '',
      action: 'hereur',
    };
    const finish: PlotEvent = {
      id: '',
      userName: whoIs ?? '',
      action: 'hereur',
    };

    // 100個ずつ送信
    const qty = stroke.length /~ 100;
    for (let index = 0; index < qty; index++) {
      queue.push({array: stroke.slice(index, 100)});
    }*/
    queue.push(infoTag);
    stroke.forEach(plot => queue.push(plot));
    if (sendData) sendData(queue);
  };

  const supressClear = () => {
    stroke.splice(0);
    strokeIndices.clear();
  };

  const closed = () => {
    setInformation(null);
  };

  const setSendData = (val: (stroke: PlotEvent[]) => void) => (sendData = val);

  const setAckJoinned = (val: boolean) => (ackJoinned = val);
  const setGotHereUR = (val: boolean) => (gotHereUR = val);

  let userNameToReconnect = () => {
    return sessionStorage.userNameToReconnect;
  };
  const setUserNameToReconnect = (val: string) => {
    sessionStorage.userNameToReconnect = val;
  };
  const roomNameToReconnect = () => {
    return sessionStorage.roomNameToReconnect;
  };
  const setRoomNameToReconnect = (val: string) => {
    sessionStorage.roomNameToReconnect = val;
  };

  const setSharingMaster = (val: boolean) => {
    sharingMaster = val;
  };

  const hideIntroContainer = (): void => {
    sessionStorage.isShowIntroContainer = 'false';
  };
  const showIntroContainer = (): void => {
    sessionStorage.isShowIntroContainer = 'true';
  };
  const isShowIntroContainer = (): boolean => {
    return (sessionStorage.isShowIntroContainer ?? 'true') === 'true' ? true : false;
  };

  const getPlotEventListeners = (): ((plotEvent: PlotEvent) => void)[] => {
    return plotEventListeners;
  };

  return {
    getDrawMode,
    addPlotEvent,
    undo,
    redo,
    clear,
    execClear,
    reject,
    save,
    setMode,
    addPlotEventListener,
    removePlotEventListener,
    addStrokeListener,
    removeStrokeListener,
    resized,
    offsetX,
    setOffsetX,
    offsetY,
    setOffsetY,
    screenScale,
    setScreenScale,
    uuid,
    setStrokeColor,
    strokeColor,
    setRealWidth,
    setRealHeight,
    setUpperBias,
    upperBias,
    setBodyBound,
    bodyBound,
    addResizeListener,
    removeResizeListener,
    setShowCanvas,
    addSwitchCanvasListener,
    removeSwitchCanvasListener,
    addBroadcastListener,
    removeBroadcastListener,
    supressClear,
    closed,
    ackJoinned,
    setAckJoinned,
    gotHereUR,
    setGotHereUR,
    setSendData,
    userNameToReconnect,
    setUserNameToReconnect,
    roomNameToReconnect,
    setRoomNameToReconnect,
    sharingMaster,
    setSharingMaster,
    hideIntroContainer,
    showIntroContainer,
    isShowIntroContainer,
    getPlotEventListeners,
  };
};

export default useStrokeState;
