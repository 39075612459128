import React from 'react';
import { styled, Theme } from '@material-ui/core/styles';

import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
import Room from './components/Room/Room';
import Video from 'twilio-video';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import useLogSender from './hooks/useLogSender/useLogSender';
//import { useAppState } from './state';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

let lastData = '';

export default function App() {
  const roomState = useRoomState();

  // ログ取りコード開始 ///////////////////////////////////////////////
  // 10回前後、タイムスタンプまで同じデータが届くため、
  // 前回値と比較して同じであれば出力しないよう調整
  const { PostToServer } = useLogSender();
  const { Logger } = Video;
  const logger = Logger.getLogger('twilio-video');
  const loggingLevel = process.env.REACT_APP_ENV_LOGGING_LEVEL;
  logger.setLevel(loggingLevel as Video.Log.LogLevelDesc);
  const originalFactory = logger.methodFactory;
  logger.methodFactory = function(methodName, level, loggerName) {
    const method = originalFactory(methodName, level, loggerName);
    return function(datetime, logLevel, component, message, data) {
      method(datetime, logLevel, component, message, data);
      if (message === 'event' && (data?.group ?? '') === 'signaling') {
        const jsonValue = JSON.stringify(data ?? '');
        if (lastData !== jsonValue) {
          lastData = jsonValue;
          const room = sessionStorage.roomName ?? ''; // まだ決まっていない場合に、時刻で対象を絞り込める
          const userName = sessionStorage.userName ?? ''; // 可能性があるので残すことにした
          if (data.name === 'waiting') {
            //console.warn('Twilio\'s signaling server is busy.');
            const toSend = { ...data, room, userName, description: "Twilio's signaling server is busy." };
            PostToServer(toSend);
          } else if (data.name === 'connecting') {
            //console.log('Connecting to Twilio\'s signaling server.');
            const toSend = { ...data, room, userName, description: "Connecting to Twilio's signaling server." };
            PostToServer(toSend);
          } else if (data.name === 'open') {
            //console.log('Connected to Twilio\'s signaling server.');
            const toSend = { ...data, room, userName, description: "Connected to Twilio's signaling server." };
            PostToServer(toSend);
          } else if (data.name === 'closed') {
            if (data.level === 'error') {
              //const { payload: { reason } } = data;
              //console.error('Connection to Twilio\'s signaling server abruptly closed:', data.reason);
              const toSend = {
                ...data,
                room,
                userName,
                description: "Connection to Twilio's signaling server abruptly closed.",
              };
              PostToServer(toSend);
            } else {
              //console.log('Connection to Twilio\'s signaling server closed.');
              const toSend = {
                ...data,
                room,
                userName,
                description: "Connection to Twilio's signaling server closed.",
              };
              PostToServer(toSend);
            }
          }
        }
      }
    };
  };
  const onUpdateRoomAndUser = (room: string, name: string) => {
    sessionStorage.roomName = room;
    sessionStorage.userName = name;
  };
  // ログ取りコード終了 ///////////////////////////////////////////////

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <Container style={{ height }}>
      {roomState === 'disconnected' ? (
        <PreJoinScreens onUpdateRoomAndUser={onUpdateRoomAndUser} />
      ) : (
        <Main>
          <ReconnectingNotification />
          <RecordingNotifications />
          <MobileTopMenuBar />
          <Room />
          <MenuBar />
        </Main>
      )}
    </Container>
  );
}
