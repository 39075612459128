// Disabled有無
import useScreenShareParticipant from '../useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useCanvasDisabled() {
  // Disabled有無
  const { isSharingScreen } = useVideoContext();
  const screenShareParticipant = useScreenShareParticipant();
  // const isDisabled = !isSharingScreen // 自分のみ
  // const isDisabled = !screenShareParticipant // 自分か相手か
  const isDisabled = !screenShareParticipant && !isSharingScreen;

  return isDisabled;
}
