import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import useChatContext from '../../../../hooks/useChatContext/useChatContext';
import useStrokeState from '../../../../hooks/useStrokeState/useStrokeState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { disconnect } = useChatContext();
  const { supressClear } = useStrokeState();

  const handleOnClick = async () => {
    room?.disconnect();
    disconnect();
    // 画面共有＆描画中であってもなくても描画を削除
    supressClear();
  };

  return (
    <Button onClick={handleOnClick} className={clsx(classes.button, props.className)} data-cy-disconnect>
      <strong>面談終了</strong>
    </Button>
  );
}
