import WebSocketClient from './webSocketClient';

const Client = {
  create: async (token: string) => {
    return WebSocketClient.create(token);
  },
  disconnect: async () => {
    return WebSocketClient.disconnect();
  },
};

export { Client };
