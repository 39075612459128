import React from 'react';
import { LocalTrackPublication, Participant, RemoteTrackPublication, Track, TrackPublication } from 'twilio-video';
import Publication from '../Publication/Publication';
import usePublications from '../../hooks/usePublications/usePublications';

interface ParticipantTracksProps {
  participant: Participant;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
  videoPriority?: Track.Priority | null;
  isLocalParticipant?: boolean;
  allowedTracks?: string[];
  prioritizedTrack?: string;
  maxTracks?: number;
  uniqueTracks?: string[][];
}

/*
 *  The object model for the Room object (found here: https://www.twilio.com/docs/video/migrating-1x-2x#object-model) shows
 *  that Participant objects have TrackPublications, and TrackPublication objects have Tracks.
 *
 *  The React components in this application follow the same pattern. This ParticipantTracks component renders Publications,
 *  and the Publication component renders Tracks.
 */

export default function ParticipantTracks({
  participant,
  videoOnly,
  enableScreenShare,
  videoPriority,
  isLocalParticipant,
  allowedTracks,
  prioritizedTrack,
  maxTracks,
  uniqueTracks,
}: ParticipantTracksProps) {
  const publications = usePublications(participant);

  const filteredPublications: (LocalTrackPublication | RemoteTrackPublication)[] = [];

  // Original logic
  /*
  const hasScreenTrack = publications.some(p => p.trackName.includes('screen'))
  if (enableScreenShare && hasScreenTrack) {
    filteredPublications.push(...publications.filter(p => !p.trackName.includes('camera')));
  } else {
    filteredPublications.push(...publications.filter(p => !p.trackName.includes('screen')));
  }
  */

  const DEFAULT_ALLOWED_TRACKS = ['camera', 'screen'];
  const DEFAULT_PRIORITIZED_TRACK = 'screen';
  const DEFAULT_MAX_TRACKS = 1;
  const DEFAULT_UNIQUE_TRACKS: string[][] = [];

  const usedMaxTracks = maxTracks || DEFAULT_MAX_TRACKS;
  const usedPrioritizedTrack = prioritizedTrack || DEFAULT_PRIORITIZED_TRACK;
  const usedAllowedTracks = allowedTracks || DEFAULT_ALLOWED_TRACKS;
  const usedUniqueTracks = uniqueTracks || DEFAULT_UNIQUE_TRACKS;

  const hasTrackKey = (trackName: string, trackKey: string) => trackName.includes(trackKey);
  const hasAnyTrackKey = (trackName: string, trackKeys: string[]) =>
    trackKeys.filter(k => hasTrackKey(trackName, k)).length > 0;

  const addedTrackNames: string[] = [];
  filteredPublications.push(
    ...publications
      .sort(a => {
        // UNIQUE処理前に実行
        // PRIORITIZED TRACK
        if (a.trackName.includes(usedPrioritizedTrack)) {
          return -1;
        } else {
          return 1;
        }
      })
      .filter(p => {
        // p.kind // audio video data.
        const trackName = p.kind === 'audio' ? 'audio' : p.trackName;

        // ALLOWED TRACKS

        if (!hasAnyTrackKey(trackName, usedAllowedTracks)) {
          console.debug('track filtered out', { trackName, usedAllowedTracks });
          return false;
        }

        // UNQIUE TRACKS
        if (
          usedUniqueTracks.filter(uTracks => {
            return (
              hasAnyTrackKey(trackName, uTracks) && addedTrackNames.filter(n => hasAnyTrackKey(n, uTracks)).length > 0 // IS UNIQUE TRACK
            ); // OTHER UNIQUE REQUIRED TRACK ADDED
          }).length > 0
        ) {
          console.debug('track filtered out', { trackName, usedUniqueTracks, addedTrackNames });
          return false;
        }

        addedTrackNames.push(trackName);
        return true;
      })
      /*
    .sort((a) => {
      // PRIORITIZED TRACK
      if (a.trackName.includes(usedPrioritizedTrack)) {
        return -1
      } else {
        return 1
      }
    })
    */
      .slice(0, usedMaxTracks) // MAX TRACKS
  ); //
  if (filteredPublications.length !== publications.length) {
    console.warn('tracks filtered', { filteredPublications, publications });
  }

  // screen test
  // filteredPublications = publications.filter(p => p.trackName.includes('screen'));
  console.debug({
    publications,
    filteredPublications,
    usedAllowedTracks,
    prioritizedTrack,
    maxTracks,
  });
  // END: Original filtering
  const usedKeys: string[] = [];

  return (
    <>
      {filteredPublications.map(publication => {
        // const key = publication.kind
        const key = publication.trackName;
        // const key = publication.trackSid
        if (usedKeys.includes(key)) {
          console.warn(`ParticipantTracks: duplicate key in Publication: ${key}`);
        }
        usedKeys.push(key);

        return (
          <Publication
            key={key}
            publication={publication}
            participant={participant}
            isLocalParticipant={isLocalParticipant}
            videoOnly={videoOnly}
            videoPriority={videoPriority}
          />
        );
      })}
    </>
  );
}
