import { useReducer, useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { CanvasDrawingToolsData } from '../../types';
import { createCanvasDrawingToolsData } from '../../utility';

const initialCanvasDrawingToolsData = createCanvasDrawingToolsData();

const canvasDrawingToolsDataReducer = (
  state: CanvasDrawingToolsData,
  action: Partial<CanvasDrawingToolsData>
): CanvasDrawingToolsData => {
  const newState = { ...state, ...action };
  console.debug('canvas drawing tools data change', { action, newState });
  emitCustomEvent('canvas-drawing-tools-data', newState); // HACK。普通はこれが必要ない。
  return newState;
};

export default function useCanvasDrawingToolsData() {
  // const [canvasDrawingToolsData, setCanvasDrawingToolsData] = useState<CanvasDrawingToolsData>(createCanvasDrawingToolsData);
  const [canvasDrawingToolsData, setCanvasDrawingToolsData] = useReducer(
    canvasDrawingToolsDataReducer,
    initialCanvasDrawingToolsData
  );

  return [canvasDrawingToolsData, setCanvasDrawingToolsData] as const;
}
