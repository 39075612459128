const useLogSender = () => {
  const PostToServer = (data: any): Promise<{ result: boolean; additional: any }> => {
    return new Promise((resolve, reject) => {
      const temp = process.env.REACT_APP_ENV_LOG_SERVER_URL;
      if (!temp) {
        resolve({ result: false, additional: 'server url was missing' });
      } else {
        fetch(temp, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then(res => {
            resolve({ result: true, additional: res });
          })
          .catch(e => {
            reject({ result: false, additional: e });
          });
      }
    });
  };

  return {
    PostToServer,
  };
};

export default useLogSender;
