import React, { useState, useEffect, useCallback } from 'react';

import AudioOutputList from '../DeviceSelectionDialog/AudioOutputList/AudioOutputList';
import {
  DialogContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Button,
  Theme,
  DialogTitle,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  SignalCellularAlt,
  Web as WebIcon,
  Videocam,
  Mic as MicIcon,
  VideocamOutlined,
  MicNone,
  VolumeUp,
} from '@material-ui/icons';

import { LocalVideoTrack, LocalAudioTrack } from 'twilio-video';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY, SELECTED_AUDIO_INPUT_KEY } from '../../constants';
import useDevices from '../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useLocalVideoToggle from '../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import VideoTrack from '../VideoTrack/VideoTrack';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import AudioLevelIndicatorHorz from '../AudioLevelIndicator/AudioLevelIndicatorHorz';
import MovieTheater from './MovieTheater/MovieTheater';
import MeasureNetwork from './MeasureNetwork/MeasureNetwork';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: '600px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  button: {
    float: 'right',
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
  description: {
    minWidth: '600px',
    padding: '16px 24px',
  },
  preview: {
    width: '300px',
    maxHeight: '200px',
    margin: '0.5em 0',
    '& video': {
      maxHeight: '200px',
    },
  },
}));
const styleTd = { padding: '4px 8px' };
const checkButton = { border: '1px solid #aaa', padding: '0 16px' };
const resultFrame = { border: '1px solid #aaa', padding: '4px 8px' };
const setButton = { border: '1px solid #aaa', padding: '0 16px', marginLeft: '8px', marginBottom: '32px' };

const CheckEnvironmentDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const classes = useStyles();

  const [videoSelection, setVideoSelection] = useState(false);
  const [useVideo, setUseVideo] = useState<boolean>(true); // ここはミュート状態を復旧する
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();
  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamVideoTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId = mediaStreamVideoTrack?.getSettings().deviceId || storedLocalVideoDeviceId;
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  function replaceVideoTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  const { audioInputDevices } = useDevices();
  const [audioSelection, setAudioSelection] = useState(false);
  const [useAudio, setUseAudio] = useState<boolean>(true); // ここはミュート状態を復旧する
  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const srcMediaStreamTrack = localAudioTrack?.noiseCancellation?.sourceTrack;
  const mediaStreamAudioTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId =
    srcMediaStreamTrack?.getSettings().deviceId || mediaStreamAudioTrack?.getSettings().deviceId;
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  function replaceAudioTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  const [videoRunning, setVideoRunning] = useState(false);
  const [videoRunningSelect, setVideoRunningSelect] = useState(-1);

  const [audioRunning, setAudioRunning] = useState(false);
  const [audioRunningSelect, setAudioRunningSelect] = useState(-1);

  const { audioOutputDevices } = useDevices();
  const [audioOutput, setAudioOutput] = useState(false);
  const [audioOutputSelect, setAudioOutputSelect] = useState(-1);

  const [timeElapsed, setTimeElapsed] = useState(0);

  // idが一致しているかどうか
  const isMatch = (a?: string, b?: string): boolean => {
    if (!a) return false;
    else if (!b) return false;
    else return a === b;
  };

  // 何もしない関数
  const nop = () => {
    //console.log('nop');
  };
  // ビットレート計測トリガー
  const checkBitrate = () => {
    // 開かれているペインを閉じる
    setVideoSelection(false);
    setAudioSelection(false);
    setVideoRunning(false);
    setAudioRunning(false);
    setAudioOutput(false);

    const resultBitrate = document.getElementById('resultBitrate');
    if (resultBitrate) {
      // 100msで良しとしてみる
      if (timeElapsed <= 100) {
        resultBitrate.innerText = '〇';
      } else {
        resultBitrate.innerText = '×';
      }
    }
  };
  // Webブラウザー検知トリガー
  const checkUserAgent = () => {
    // 開かれているペインを閉じる
    setVideoSelection(false);
    setAudioSelection(false);
    setVideoRunning(false);
    setAudioRunning(false);
    setAudioOutput(false);

    const agentJudgement = document.getElementById('agentJudgement');
    const resultAgentJudgement = document.getElementById('resultAgentJudgement');
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf('chrome') != -1) {
      agentJudgement ? (agentJudgement.innerText = 'Chromeです。') : nop();
      resultAgentJudgement ? (resultAgentJudgement.innerText = '〇') : nop();
    } else if (agent.indexOf('crios') != -1) {
      agentJudgement ? (agentJudgement.innerText = 'Chromeです。') : nop();
      resultAgentJudgement ? (resultAgentJudgement.innerText = '〇') : nop();
    } else {
      agentJudgement ? (agentJudgement.innerText = '非Chromeです。') : nop();
      resultAgentJudgement ? (resultAgentJudgement.innerText = 'ー') : nop();
    }
  };
  // 現在選択されているカメラの取得
  const checkSelectedVideo = () => {
    // 開かれているペインを閉じる
    setAudioSelection(false);
    setVideoRunning(false);
    setAudioRunning(false);
    setAudioOutput(false);

    // ペインの操作 - 開かれていれば閉じる
    if (videoSelection) {
      setVideoSelection(false);
    } else {
      const videoSelector = document.getElementById('videoSelector') as HTMLSelectElement;
      if (videoSelector) videoSelector.value = localVideoInputDeviceId || '';
      setVideoSelection(true);
    }
    judgeVideoStatus();
  };
  // 状態の判定
  // カメラを使用しないボタンはPCなどの機能上存在しない状態なので
  // カメラがない場合は単に判定を「ー」とし、
  // カメラがある場合はミュートすることとした
  const judgeVideoStatus = useCallback(() => {
    const resultVideoValidation = document.getElementById('resultVideoValidation');
    if (resultVideoValidation) {
      // カメラ接続の確認ペインが開かれている場合のみ
      if (videoSelection) {
        if (useVideo) {
          if (videoInputDevices.length === 0) {
            // カメラがない
            resultVideoValidation.innerText = '×';
          } else {
            // 選択されている
            if (localVideoInputDeviceId) {
              resultVideoValidation.innerText = '〇';
            } else {
              resultVideoValidation.innerText = '×'; // 存在しない状態のはず
            }
          }
        } else {
          if (videoInputDevices.length === 0) {
            // カメラがない
            resultVideoValidation.innerText = 'ー';
          } else {
            // 選択されている
            if (localVideoInputDeviceId) {
              resultVideoValidation.innerText = '〇';
            } else {
              resultVideoValidation.innerText = 'ー'; // 存在しない状態のはず
            }
          }
        }
      }
    }
  }, [videoSelection, localVideoInputDeviceId, useVideo, videoInputDevices.length]);
  // カメラ選択の変更
  /*const selChgVideo = () => {
    const videoSelector = document.getElementById('videoSelector') as HTMLSelectElement;
    if (localVideoInputDeviceId !== videoSelector.value) replaceVideoTrack(videoSelector.value || '');
  };*/
  // useVideoの状態変更時処理
  useEffect(() => {
    judgeVideoStatus();
  }, [judgeVideoStatus, useVideo]);
  // カメラのミュート状態変更
  // 先に状態を切り替えて変わった状態をUIに反映
  const switchUseVideo = () => {
    const deviceSelectionVideoUpper = document.getElementById('DeviceSelectionVideoUpper');
    const deviceSelectionVideoLower = document.getElementById('DeviceSelectionVideoLower');

    if (deviceSelectionVideoUpper) deviceSelectionVideoUpper.click();
    else if (deviceSelectionVideoLower) deviceSelectionVideoLower.click();

    const videoSelector = document.getElementById('videoSelector') as HTMLSelectElement;
    videoSelector ? (videoSelector.value = localVideoInputDeviceId || '') : nop();
    setUseVideo(isVideoEnabled ?? false);

    judgeVideoStatus();
  };

  // 現在選択されているマイクの取得
  const checkSelectedAudio = () => {
    // 開かれているペインを閉じる
    setVideoSelection(false);
    setVideoRunning(false);
    setAudioRunning(false);
    setAudioOutput(false);

    // ペインの操作 - 開かれていれば閉じる
    if (audioSelection) {
      setAudioSelection(false);
    } else {
      const videoSelector = document.getElementById('videoSelector') as HTMLSelectElement;
      if (videoSelector) videoSelector.value = localVideoInputDeviceId || '';
      setAudioSelection(true);
    }
    judgeVideoStatus();
  };
  // マイク状態の判定
  // マイクを使用しないボタンはPCなどの機能上存在しない状態なので
  // マイクがない場合は単に判定を「ー」とし、
  // マイクがある場合はミュートすることとした
  const judgeAudioStatus = useCallback(() => {
    const resultAudioValidation = document.getElementById('resultAudioValidation');
    if (resultAudioValidation) {
      // マイク接続の確認ペインが開かれている場合のみ
      if (audioSelection) {
        if (useAudio) {
          if (videoInputDevices.length === 0) {
            // マイクがない
            resultAudioValidation.innerText = '×';
          } else {
            // 選択されている
            if (localVideoInputDeviceId) {
              resultAudioValidation.innerText = '〇';
            } else {
              resultAudioValidation.innerText = '×'; // 存在しない状態のはず
            }
          }
        } else {
          if (videoInputDevices.length === 0) {
            // マイクがない
            resultAudioValidation.innerText = 'ー';
          } else {
            // 選択されている
            if (localVideoInputDeviceId) {
              resultAudioValidation.innerText = '〇';
            } else {
              resultAudioValidation.innerText = 'ー'; // 存在しない状態のはず
            }
          }
        }
      }
    }
  }, [audioSelection, localVideoInputDeviceId, useAudio, videoInputDevices.length]);
  // マイク選択の変更
  /*const selChgAudio = () => {
    const audioSelector = document.getElementById('audioSelector') as HTMLSelectElement;
    if (localAudioInputDeviceId !== audioSelector.value) replaceAudioTrack(audioSelector.value || '');
  };*/
  // useAudioの状態変更時処理
  useEffect(() => {
    judgeAudioStatus();
  }, [judgeAudioStatus, useAudio]);
  // マイクのミュート状態変更
  // 先に状態を切り替えて変わった状態をUIに反映
  const switchUseAudio = () => {
    const deviceSelectionAudioUpper = document.getElementById('DeviceSelectionAudioUpper');
    const deviceSelectionAudioLower = document.getElementById('DeviceSelectionAudioLower');

    if (deviceSelectionAudioUpper) deviceSelectionAudioUpper.click();
    else if (deviceSelectionAudioLower) deviceSelectionAudioLower.click();

    const audioSelector = document.getElementById('audioSelector') as HTMLSelectElement;
    audioSelector ? (audioSelector.value = localAudioInputDeviceId || '') : nop();
    setUseAudio(isAudioEnabled ?? false);

    judgeAudioStatus();
  };
  // カメラ動作の確認トリガー
  const checkVideoRunning = () => {
    // 開かれているペインを閉じる
    setVideoSelection(false);
    setAudioSelection(false);
    setAudioRunning(false);
    setAudioOutput(false);

    // ペインの操作 - 開かれていれば閉じる
    if (videoRunning) {
      setVideoRunning(false);
    } else {
      setVideoRunning(true);
    }
  };
  // カメラのはいいいえ選択時
  // 型は「e.target.value」がビルドエラーになるため狙ってanyにしてある
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selChgVideoRunningSelect = (e?: any) => {
    const value = e ? e.target.value : '';
    if (value !== '') setVideoRunningSelect(value);
    const resultVideoRunning = document.getElementById('resultVideoRunning');
    if (resultVideoRunning) {
      if (useVideo) {
        if (videoInputDevices.length === 0) {
          // カメラがない
          resultVideoRunning.innerText = '×';
        } else if (value === '') {
          // カメラを使わないボタンをONにしてもOFFにしても一旦ハイフンにする
          resultVideoRunning.innerText = 'ー';
        } else {
          // 選択されている
          if (value === 'okay') {
            resultVideoRunning.innerText = '〇';
          } else if (value === 'noGood') {
            resultVideoRunning.innerText = '×';
          }
        }
      } else {
        // カメラを使わない場合は何があってもハイフン表示
        resultVideoRunning.innerText = 'ー';
      }
    }
  };
  // マイク動作の確認トリガー
  const checkAudioRunning = () => {
    // 開かれているペインを閉じる
    setVideoSelection(false);
    setAudioSelection(false);
    setVideoRunning(false);
    setAudioOutput(false);

    // ペインの操作 - 開かれていれば閉じる
    if (audioRunning) {
      setAudioRunning(false);
    } else {
      setAudioRunning(true);
    }
  };
  // マイクのはいいいえ選択時
  // 型は「e.target.value」がビルドエラーになるため狙ってanyにしてある
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selChgAudioRunningSelect = (e?: any) => {
    const value = e ? e.target.value : '';
    if (value !== '') setAudioRunningSelect(value);
    const resultAudioRunning = document.getElementById('resultAudioRunning');
    if (resultAudioRunning) {
      if (useAudio) {
        if (audioInputDevices.length === 0) {
          // マイクがない
          resultAudioRunning.innerText = '×';
        } else if (value === '') {
          // マイクを使わないボタンをONにしてもOFFにしても一旦ハイフンにする
          resultAudioRunning.innerText = 'ー';
        } else {
          // 選択されている
          if (value === 'okay') {
            resultAudioRunning.innerText = '〇';
          } else if (value === 'noGood') {
            resultAudioRunning.innerText = '×';
          }
        }
      } else {
        // マイクを使わない場合は何があってもハイフン表示
        resultAudioRunning.innerText = 'ー';
      }
    }
  };

  // マイク動作の確認トリガー
  const checkAudioOutput = () => {
    // 開かれているペインを閉じる
    setVideoSelection(false);
    setAudioSelection(false);
    setVideoRunning(false);
    setAudioRunning(false);

    // ペインの操作 - 開かれていれば閉じる
    if (audioOutput) {
      setPlayback(false);
      setAudioOutput(false);
    } else {
      setAudioOutput(true);
      setPlayback(true);
    }
  };
  // スピーカーのはいいいえ選択時
  // 型は「e.target.value」がビルドエラーになるため狙ってanyにしてある
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selChgAudioOutputSelect = (e?: any) => {
    const value = e ? e.target.value : '';
    if (value !== '') setAudioOutputSelect(value);
    const resultAudioOutput = document.getElementById('resultAudioOutput');
    if (resultAudioOutput) {
      if (useAudio) {
        if (audioOutputDevices.length === 0) {
          const userAgent = window.navigator.userAgent.toLowerCase();
          if (userAgent.indexOf('safari') === -1) {
            // スピーカー（音声機能）がない
            resultAudioOutput.innerText = '×';
          } else {
            // 選択されている
            if (value === 'okay') {
              resultAudioOutput.innerText = '〇';
            } else if (value === 'noGood') {
              resultAudioOutput.innerText = '×';
            }
          }
        } else if (value === '') {
          // スピーカー（音声機能）を使わないボタンをONにしてもOFFにしても一旦ハイフンにする
          resultAudioOutput.innerText = 'ー';
        } else {
          // 選択されている
          if (value === 'okay') {
            resultAudioOutput.innerText = '〇';
          } else if (value === 'noGood') {
            resultAudioOutput.innerText = '×';
          }
        }
      } else {
        // スピーカー（音声機能）を使わない場合は何があってもハイフン表示
        resultAudioOutput.innerText = 'ー';
      }
    }
  };
  const [isPlayback, setPlayback] = useState(false);
  useEffect(() => {
    if (!audioOutput) setPlayback(false);
  }, [audioOutput]);

  // UI初期化
  useEffect(() => {
    const videoSelector = document.getElementById('videoSelector') as HTMLSelectElement;
    videoSelector ? (videoSelector.value = localVideoInputDeviceId || '') : nop();
    setUseVideo(isVideoEnabled ?? false);

    const audioSelector = document.getElementById('audioSelector') as HTMLSelectElement;
    audioSelector ? (audioSelector.value = localAudioInputDeviceId || '') : nop();
    setUseAudio(isAudioEnabled ?? false);
  }, [isAudioEnabled, isVideoEnabled, localAudioInputDeviceId, localVideoInputDeviceId]);

  const closePanes = () => {
    // 開かれているペインを閉じる
    setVideoSelection(false);
    setAudioSelection(false);
    setVideoRunning(false);
    setAudioRunning(false);
    setAudioOutput(false);

    // はいいいえ選択を戻す
    setVideoRunningSelect(-1);
    setAudioRunningSelect(-1);
    setAudioOutputSelect(-1);
  };

  const onCloseIntegrated = () => {
    closePanes();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onCloseIntegrated} classes={{ paper: classes.paper }}>
      <DialogTitle style={{ minWidth: '600px' }}>環境チェック</DialogTitle>
      <Divider style={{ minWidth: '600px' }} />
      <div className={classes.description}>
        <InputLabel style={{ fontSize: '12pt' }}>入室前に、ご利用環境の確認をお願いします。</InputLabel>
      </div>

      <DialogContent className={classes.container}>
        <table>
          <tbody>
            <tr>
              <td style={{ width: '5%' }}>
                <SignalCellularAlt />
              </td>
              <td style={{ width: '25%', padding: '4px 8px' }}>通信環境の確認</td>
              <td style={{ width: '15%' }}>
                <Button onClick={() => checkBitrate()} className={classes.button} style={checkButton}>
                  チェック
                </Button>
              </td>
              <td style={{ width: '20%', textAlign: 'center' }}>{timeElapsed ? `${timeElapsed}ms` : '< 1ms'}</td>
              <td style={{ width: '5%', textAlignLast: 'center', WebkitWritingMode: 'vertical-lr' }}>
                <MeasureNetwork
                  valueCB={value => {
                    setTimeElapsed(value);
                  }}
                />
              </td>
              <td style={{ width: '10%', textAlign: 'center' }}>
                <div style={resultFrame} id="resultBitrate">
                  ー
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <WebIcon />
              </td>
              <td {...{ style: styleTd }}>推奨ブラウザの確認</td>
              <td>
                <Button onClick={() => checkUserAgent()} className={classes.button} style={checkButton}>
                  チェック
                </Button>
              </td>
              <td colSpan={2} id="agentJudgement" style={{ textAlign: 'center' }}></td>
              <td style={{ textAlign: 'center' }}>
                <div style={resultFrame} id="resultAgentJudgement">
                  ー
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <Videocam />
              </td>
              <td {...{ style: styleTd }}>カメラ接続の確認</td>
              <td>
                <Button onClick={() => checkSelectedVideo()} className={classes.button} style={checkButton}>
                  チェック
                </Button>
              </td>
              <td>
                <Button
                  onClick={() => {
                    switchUseVideo();
                  }}
                  className={classes.button}
                  style={{ border: useVideo ? '4px solid #aaa' : '4px solid #f00', padding: '0 16px' }}
                >
                  使用しない
                </Button>
              </td>
              <td></td>
              <td style={{ textAlign: 'center' }}>
                <div style={resultFrame} id="resultVideoValidation">
                  ー
                </div>
              </td>
            </tr>
            <tr style={{ display: videoSelection ? 'contents' : 'none' }}>
              <td></td>
              <td {...{ style: styleTd }} colSpan={3}>
                <p>使用するデバイスを選択してください</p>
                {videoInputDevices.length > 1 ? (
                  /*<select size={4} id="videoSelector" style={{ width: '75%' }}>
                    {videoInputDevices.map(item => (
                      <option
                        key={item.deviceId}
                        value={item.deviceId}
                        style={{
                          padding: '4px 8px',
                          fontWeight: isMatch(localVideoInputDeviceId || '', item.deviceId) ? 'bold' : 'normal',
                        }}
                      >
                        {item.label}
                      </option>
                    ))}
                  </select>*/
                  <Select
                    id="videoSelector"
                    onChange={e => replaceVideoTrack(e.target.value as string)}
                    value={localVideoInputDeviceId || ''}
                    variant="outlined"
                  >
                    {videoInputDevices.map(device => (
                      <MenuItem value={device.deviceId} key={device.deviceId}>
                        {device.label}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Typography>{localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}</Typography>
                )}
                {/*<Button onClick={() => selChgVideo()} style={setButton}>
                  設定
                </Button>*/}
              </td>
              <td colSpan={3}></td>
            </tr>
            <tr>
              <td>
                <MicIcon />
              </td>
              <td {...{ style: styleTd }}>マイク接続の確認</td>
              <td>
                <Button onClick={() => checkSelectedAudio()} className={classes.button} style={checkButton}>
                  チェック
                </Button>
              </td>
              <td>
                <Button
                  onClick={() => {
                    switchUseAudio();
                  }}
                  className={classes.button}
                  style={{ border: useAudio ? '4px solid #aaa' : '4px solid #f00', padding: '0 16px' }}
                >
                  使用しない
                </Button>
              </td>
              <td></td>
              <td style={{ textAlign: 'center' }}>
                <div style={resultFrame} id="resultAudioValidation">
                  ー
                </div>
              </td>
            </tr>
            <tr style={{ display: audioSelection ? 'contents' : 'none' }}>
              <td></td>
              <td {...{ style: styleTd }} colSpan={3}>
                <p>使用するデバイスを選択してください</p>
                {audioInputDevices.length > 1 ? (
                  /*<select size={4} id="audioSelector" style={{ width: '75%' }}>
                    {audioInputDevices.map(item => (
                      <option
                        key={item.deviceId}
                        value={item.deviceId}
                        style={{
                          padding: '4px 8px',
                          fontWeight: isMatch(localAudioInputDeviceId || '', item.deviceId) ? 'bold' : 'normal',
                        }}
                      >
                        {item.label}
                      </option>
                    ))}
                  </select>*/
                  <Select
                    id="audioSelector"
                    onChange={e => replaceAudioTrack(e.target.value as string)}
                    value={localAudioInputDeviceId || ''}
                    variant="outlined"
                  >
                    {audioInputDevices.map(device => (
                      <MenuItem value={device.deviceId} key={device.deviceId}>
                        {device.label}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Typography>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</Typography>
                )}
                {/*<Button onClick={() => selChgAudio()} style={setButton}>
                  設定
                </Button>*/}
              </td>
              <td colSpan={3}></td>
            </tr>
            <tr>
              <td>
                <VideocamOutlined />
              </td>
              <td {...{ style: styleTd }}>カメラ動作の確認</td>
              <td>
                <Button onClick={() => checkVideoRunning()} className={classes.button} style={checkButton}>
                  チェック
                </Button>
              </td>
              <td>
                <Button
                  onClick={() => {
                    switchUseVideo();
                    selChgVideoRunningSelect();
                  }}
                  className={classes.button}
                  style={{ border: useVideo ? '4px solid #aaa' : '4px solid #f00', padding: '0 16px' }}
                >
                  使用しない
                </Button>
              </td>
              <td></td>
              <td style={{ textAlign: 'center' }}>
                <div style={resultFrame} id="resultVideoRunning">
                  ー
                </div>
              </td>
            </tr>
            <tr style={{ display: videoRunning ? 'contents' : 'none' }}>
              <td></td>
              <td {...{ style: styleTd }} colSpan={3}>
                自分の映像が正常に表示されていますか？
                <br />
                <RadioGroup
                  aria-label="gender"
                  id="VideoRunningSelection"
                  value={videoRunningSelect}
                  style={{ display: 'block' }}
                  onChange={e => selChgVideoRunningSelect(e)}
                >
                  <FormControlLabel value="okay" control={<Radio color="primary" />} label="はい" />
                  <FormControlLabel value="noGood" control={<Radio color="primary" />} label="いいえ" />
                </RadioGroup>
                {localVideoTrack && (
                  <div className={classes.preview} style={{ marginLeft: '-10px' }}>
                    <VideoTrack isLocal track={localVideoTrack} key={localVideoTrack.name} />
                  </div>
                )}
                {videoInputDevices.length > 1 ? (
                  <></>
                ) : (
                  <Typography>{localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}</Typography>
                )}
              </td>
              <td colSpan={3}></td>
            </tr>

            <tr>
              <td>
                <MicNone />
              </td>
              <td {...{ style: styleTd }}>マイク動作の確認</td>
              <td>
                <Button onClick={() => checkAudioRunning()} className={classes.button} style={checkButton}>
                  チェック
                </Button>
              </td>
              <td>
                <Button
                  onClick={() => {
                    switchUseAudio();
                    selChgAudioRunningSelect();
                  }}
                  className={classes.button}
                  style={{ border: useAudio ? '4px solid #aaa' : '4px solid #f00', padding: '0 16px' }}
                >
                  使用しない
                </Button>
              </td>
              <td></td>
              <td style={{ textAlign: 'center' }}>
                <div style={resultFrame} id="resultAudioRunning">
                  ー
                </div>
              </td>
            </tr>
            <tr style={{ display: audioRunning ? 'contents' : 'none' }}>
              <td></td>
              <td {...{ style: styleTd }} colSpan={3}>
                声を出してみてください、自分の音声で反応していますか？
                <br />
                <RadioGroup
                  aria-label="gender"
                  id="AudioRunningSelection"
                  value={audioRunningSelect}
                  style={{ display: 'block' }}
                  onChange={e => selChgAudioRunningSelect(e)}
                >
                  <FormControlLabel value="okay" control={<Radio color="primary" />} label="はい" />
                  <FormControlLabel value="noGood" control={<Radio color="primary" />} label="いいえ" />
                </RadioGroup>
                <AudioLevelIndicatorHorz audioTrack={localAudioTrack} color="black" />
                {audioInputDevices.length > 1 ? (
                  <></>
                ) : (
                  <Typography>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</Typography>
                )}
              </td>
              <td colSpan={3}></td>
            </tr>

            <tr>
              <td>
                <VolumeUp />
              </td>
              <td {...{ style: styleTd }}>スピーカー動作の確認</td>
              <td>
                <Button onClick={() => checkAudioOutput()} className={classes.button} style={checkButton}>
                  チェック
                </Button>
              </td>
              <td></td>
              <td></td>
              <td style={{ textAlign: 'center' }}>
                <div style={resultFrame} id="resultAudioOutput">
                  ー
                </div>
              </td>
            </tr>
            <tr style={{ display: audioOutput ? 'contents' : 'none' }}>
              <td></td>
              <td {...{ style: styleTd }} colSpan={3}>
                音声は聞こえますか？
                <br />
                <RadioGroup
                  aria-label="gender"
                  id="AudioOutputSelection"
                  value={audioOutputSelect}
                  style={{ display: 'block' }}
                  onChange={e => selChgAudioOutputSelect(e)}
                >
                  <FormControlLabel value="okay" control={<Radio color="primary" />} label="はい" />
                  <FormControlLabel value="noGood" control={<Radio color="primary" />} label="いいえ" />
                </RadioGroup>
                <MovieTheater play={isPlayback} />
                {audioOutputDevices.length > 1 ? (
                  <></>
                ) : (
                  <Typography>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio Output'}</Typography>
                )}
              </td>
              <td colSpan={3}></td>
            </tr>
          </tbody>
        </table>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primary" variant="contained" className={classes.button} onClick={onCloseIntegrated}>
          完了
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckEnvironmentDialog;
