export const NO_ID_MESSAGE = 'URLにIDがありません。ID: /:id OR ?id=:id。';
export const WAITING_MESSAGE = 'もう少しお待ちください。';
export const ROOM_FINISHED_MESSAGE = 'ルームは終了しました。';
export const SERVER_ERROR_MESSAGE = 'サーバーエラーが発生しました。';
export const REMAINING_SECONDS = '開始まで残り{ms}秒';
export const COLOR_PICKER = '色選択';
export const TEXT_TOOL = 'テキスト';
export const LINE_TOOL = '　線　';
export const ERASER_TOOL = '消しゴム';
export const UNDO = '元に戻す';
export const REDO = 'やり直し';
export const CLEAR = '消去';
export const SAVE = '保存';
export const MICROPHONE_TOGGLE = 'マイクON/OFF';
export const CAMERA_TOGGLE = 'カメラON/OFF';
export const SCREENSHARE = 'スクリーン共有';
export const COMMENT = 'コメント';
export const CHAT = 'チャット';
export const RESOURCES = '資料ボックス';
export const FINISH_CHAT = '面談終了';
export const CONFIRM_FINISH_CHAT = 'オンライン面談を終了しますか？';
export const DIALOG_CANCEL = 'キャンセル';
export const DIALOG_CONFIRM = '終了する';
export const CLEAR_ALL_CONFIRM = 'すべて消しますか？';
