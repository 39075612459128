import {
  CANVAS_EXPORT_IMAGE_MIME_TYPE,
  CANVAS_EXPORT_IMAGE_QUALITY,
  DEFAULT_MEDIA_STREAM_CONSTRAINTS,
} from './constants';
import Video, { LocalDataTrack } from 'twilio-video';
import { v1 as uuidv1 } from 'uuid';
// https://github.com/fregante/intrinsic-scale
import { contain } from 'intrinsic-scale';

export function stopStream(stream: MediaStream): void {
  stream.getTracks().forEach(track => track.stop());
}

export function streamHasAudio(stream: MediaStream): boolean {
  return stream.getTracks().filter(t => t.kind === 'audio').length > 0;
}

export function streamHasVideo(stream: MediaStream): boolean {
  return stream.getTracks().filter(t => t.kind === 'video').length > 0;
}

export function stopVideo(video: HTMLVideoElement): void {
  video.pause();
  const { srcObject } = video;
  if (srcObject instanceof MediaStream) {
    stopStream(srcObject);
  }
  video.srcObject = null;
}

export function getCamera(
  constraints: MediaStreamConstraints = DEFAULT_MEDIA_STREAM_CONSTRAINTS
): Promise<MediaStream> {
  return navigator.mediaDevices.getUserMedia(constraints);
}

export function createVideo(stream?: MediaStream): HTMLVideoElement {
  const video = document.createElement('video');
  video.setAttribute('data-createVideo', '');
  video.autoplay = true;
  if (stream) {
    video.srcObject = stream;
  }

  return video;
}

export function streamToVideo(stream: MediaStream): HTMLVideoElement {
  return createVideo(stream);
}

export async function getCameraVideo(): Promise<HTMLVideoElement> {
  const stream = await getCamera();
  return streamToVideo(stream);
}

export function getIdFromURL(): string {
  const routeId = new URL(window.location.href).pathname
    .split('/')
    .pop()
    ?.trim();
  if (routeId) {
    return routeId;
  }
  return new URL(window.location.href).searchParams.get('id') || '';
}

export function startTimer(
  initialRemainingMs: number,
  onInterval: (remainingMs: number) => void,
  intervalRateMs: number
): () => void {
  const startMs = Date.now();
  const intervalHandle = setInterval(() => {
    const elapsed = Date.now() - startMs;
    const remainingMs = initialRemainingMs - elapsed;

    if (remainingMs <= 0) {
      clearInterval(intervalHandle);
    } else {
      onInterval(remainingMs);
    }
  }, intervalRateMs);

  return () => clearInterval(intervalHandle);
}

export function waitForTimeStamp(ms: number): Promise<void> {
  return new Promise<void>(resolve => {
    const round = () => {
      if (Date.now() > ms) {
        resolve();
        return true;
      }
      return false;
    };

    const next = () =>
      window.setTimeout(() => {
        if (!round()) {
          next();
        }
      }, 1000);

    round();
    next();
  });
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Screen_Capture_API/Using_Screen_Capture#options_and_constraints
 */
export function getSharedScreen(): Promise<MediaStream> {
  return navigator.mediaDevices.getDisplayMedia({
    // https://github.com/microsoft/TypeScript/issues/33232
    video: true,
    /*
    video: {
      cursor: { ideal: 'always' } // always, motion, never,
      logicalSurface: true
    }
    */
    // audio: true // カメラのaudioを利用
  });
}

export async function toggleCameraTrack(stream: MediaStream, kind: 'video' | 'audio'): Promise<boolean> {
  const track = stream.getTracks().filter(t => t.kind === kind)[0];
  if (!track) {
    // throw new Error('No track')
    console.warn('No track', { stream, kind });
  }
  track.enabled = !track.enabled;
  return track.enabled;
}

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => {
    window.setTimeout(resolve, ms);
  });
}

export function downloadLink(url: string, fullName: string): void {
  const link = window.document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.download = fullName;

  document.body.appendChild(link);

  link.click();

  if (link.parentElement) {
    link.parentElement.removeChild(link);
  }
}

export function downloadBlob(blob: Blob, name: string): boolean {
  const url = window.URL.createObjectURL(blob);

  let extension = '';
  if (blob.type) {
    extension = blob.type.split('/')[1];
  } else {
    extension = 'txt';
  }

  const fullName = name + '.' + extension;

  // Internet Explorer
  if ((window.navigator as any).msSaveBlob) {
    (window.navigator as any).msSaveBlob(blob, fullName);
  } else {
    downloadLink(url, fullName);
  }

  return true;
}

export function download(blob: Blob, fileName: string): boolean {
  return downloadBlob(blob, fileName);
}

export const HTMLVideoElementToHTMLCanvasElement = (
  video: HTMLVideoElement,
  startCanvas?: HTMLCanvasElement
): HTMLCanvasElement => {
  const canvas = startCanvas || document.createElement('canvas');
  canvas.setAttribute('data-HTMLVideoElementToHTMLCanvasElement', '');
  canvas.width = video.videoWidth || 0;
  canvas.height = video.videoHeight || 0;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('No context');
  }
  ctx.drawImage(video, 0, 0); // videoとcanvasのサイズが一緒なのでOK

  return canvas;
};

export function canvasDrawingDataToImageSrc(data: any): string {
  if (typeof data === 'string') {
    return data;
  } else {
    // https://stackoverflow.com/questions/44147912/arraybuffer-to-blob-conversion/44148694
    const blob = new Blob([data]);
    const objectURL = URL.createObjectURL(blob);
    console.debug('canvasDrawingDataToImageSrc non string data', { data, blob, objectURL });
    return objectURL;
  }
}

/**
 * @see https://www.twilio.com/docs/video/using-datatrack-api
 * @see https://github.com/twilio/twilio-video.js/blob/master/lib/data/sender.js#L91
 * identityは複数人数で相手を特定したい場合に利用すること。
 */
export function sendMessage(message: MessageData, dataTrack: LocalDataTrack /*, identity?: string */): void {
  console.debug('sendMessage', message);
  if (!dataTrack) {
    console.warn('Failed to send message because no data track', message);
    return;
  }
  dataTrack.send(message);
  // dataTrack.send('string message') // string test
}

export function getTwilioTrackByName(name: string, participant: Video.Participant): Video.LocalTrack | undefined {
  const tracksObject = Object.fromEntries(participant.tracks);

  const track = Object.entries(tracksObject)
    .filter(([, trackPublication]) => {
      return trackPublication.trackName === name;
    })
    .map(([, trackPublication]) => {
      return (trackPublication as Video.LocalTrackPublication).track;
    })[0];

  return track;
}

export function removeTrack(name: string, participant: Video.Participant): void {
  console.debug('removeTrack', name);
  const track = getTwilioTrackByName(name, participant);
  if (!track) {
    console.warn(`No track: ${track}`);
    return;
  }
  (participant as Video.LocalParticipant).unpublishTrack(track as Video.LocalTrack);
}

/**
 * DataTrackに対して、実行する必要があり、例のように実行すればいい。
 * @example room.on('trackSubscribed', (track) => onTrackSubscribed(track, onMessage));
 */
export const onTrackSubscribed = (track: LocalDataTrack, onMessage: (data: MessageData) => void): void => {
  console.debug('onTrackSubscribed', track);
  if (track.kind === 'data') {
    track.on('message', data => {
      // string | ArrayBuffer 。Blobではない？
      console.debug('track.on(message)', data, typeof data, data.constructor);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onMessage(data as any);
    });
  }
};

export const onTrackSubscribedEx = (track: LocalDataTrack, onMessage: (data: string) => void): void => {
  console.debug('onTrackSubscribed', track);
  if (track.kind === 'data') {
    track.on('message', data => {
      // string | ArrayBuffer 。Blobではない？
      console.debug('track.on(message)', data, typeof data, data.constructor);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onMessage(data as any);
    });
  }
};

interface ImageDataDiffState {
  diffPixelIndices: { index: number[]; x: number[]; y: number[] };
  rect: { x: number; y: number; width: number; height: number };
  data: ImageData | null;
}

/**
 * imageData1 = OLD
 * imageData2 = NEW
 */
export function getImageDataDiffRect(imageData1: ImageData, imageData2: ImageData) {
  const state: ImageDataDiffState = {
    diffPixelIndices: {
      index: [],
      x: [],
      y: [],
    },
    rect: {
      x: 0,
      y: 0,
      width: imageData2.width,
      height: imageData2.height,
    },
    data: imageData2,
  };

  // サイズが異なる場合、imageData2を全部送信。
  if (imageData1.width !== imageData2.width || imageData1.height !== imageData2.height) {
    return state;
  }

  // diffPixelIndices, rect
  const d1 = imageData1.data;
  const d2 = imageData2.data;
  const PIXEL_LENGTH = 4;
  for (let i = 0; i < d1.length; i += PIXEL_LENGTH) {
    const match = d1.slice(i, PIXEL_LENGTH).filter((v, index) => d2[i + index] === v).length === PIXEL_LENGTH;
    if (!match) {
      const { x, y } = ImageDataToPoint(imageData2, i);
      state.diffPixelIndices.x.push(x);
      state.diffPixelIndices.y.push(y);
      state.diffPixelIndices.index.push(i);
    } else {
      // 変更していないところを透明にして、描画しなくてよいように。
      d2[i + 3] = 0;
    }
  }
  // 描画四角
  state.rect.x = Math.min(...state.diffPixelIndices.x) || 0;
  state.rect.y = Math.min(...state.diffPixelIndices.y) || 0;
  state.rect.width = state.rect.x + (Math.max(...state.diffPixelIndices.x) || 0);
  state.rect.height = state.rect.y + (Math.max(...state.diffPixelIndices.y) || 0);
  // https://developer.mozilla.org/en-US/docs/Web/API/ImageData/ImageData
  state.data = getImageDataPixels(imageData2, state.rect.x, state.rect.y, state.rect.width, state.rect.height);

  return state;
}

export function getImageDataPixels(
  imageData: ImageData,
  x: number,
  y: number,
  width: number,
  height: number
): ImageData {
  const canvas = document.createElement('canvas');
  canvas.setAttribute('data-getImageDataPixels', '');
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('No context');
  }
  ctx.putImageData(imageData, 0, 0);
  return ctx.getImageData(x, y, width, height);
}

export function ImageDataToPoint(imageData: ImageData, index: number): { x: number; y: number } {
  const { width } = imageData;
  const pixel = index / 4;
  const y = Math.floor(pixel / width);
  const x = pixel % width;

  return { x, y };
}

export function mergeCanvases(canvasArray: HTMLCanvasElement[]): HTMLCanvasElement {
  // Variables
  const newCanvas = document.createElement('canvas');
  newCanvas.setAttribute('data-mergeCanvases', '');
  const newCtx = newCanvas.getContext('2d');
  if (!newCtx) {
    throw new Error('No context');
  }

  // Size
  const sizes = canvasArray.map(c => ({
    width: c.width,
    height: c.height,
  }));
  const maxWidth = Math.max(...sizes.map(s => s.width));
  const maxHeight = Math.max(...sizes.map(s => s.height));
  newCanvas.width = maxWidth;
  newCanvas.height = maxHeight;

  canvasArray.forEach(canvas => {
    newCtx.drawImage(canvas, 0, 0, maxWidth, maxHeight);
  });

  return newCanvas;
}

export const arrayBufferToArray = (arrayBuffer: ArrayBuffer): any[] => {
  return Array.from(new Uint8Array(arrayBuffer));
};
export const arrayToArrayBuffer = (array: any[]): ArrayBuffer => {
  return new Uint8Array(array).buffer;
};
export const imageDataToObject = (imageData: ImageData): Object => {
  const object = {
    data: Array.from(imageData.data),
    width: imageData.width,
    height: imageData.height,
  };
  return object;
};
/**
 * 送信可能形式に変える
 */
export const serializeCanvasDrawingData = (canvasDrawingData: CanvasDrawingData): MessageData => {
  // 内部データ
  if (canvasDrawingData.type === 'dataURL') {
    // 処理必要ない
  } else if (canvasDrawingData.type === 'ImageData') {
    canvasDrawingData.data = imageDataToObject(canvasDrawingData.data as ImageData) as any;
  } else if (canvasDrawingData.type === 'arrayBuffer') {
    canvasDrawingData.data = arrayBufferToArray(canvasDrawingData.data as ArrayBuffer) as any;
    // 全体ObjectをArrayBufferにする場合、上記で何もしなくていい。
  }

  // 全体Object
  if (canvasDrawingData.type === 'dataURL') {
    return JSON.stringify(canvasDrawingData);
  } else if (canvasDrawingData.type === 'ImageData') {
    return JSON.stringify(canvasDrawingData);
  } else if (canvasDrawingData.type === 'arrayBuffer') {
    return JSON.stringify(canvasDrawingData);
    // ArrayBufferとして送信するか、dataを配列にしてから全体をstringify
  } else {
    console.error('Invalid type', canvasDrawingData.type);
    return '';
  }
};

export const disableFabric = (fabricCanvas: fabric.Canvas): void => {
  const element = fabricCanvas.getElement();
  if (!element) {
    console.debug('No element', fabricCanvas);
    return;
  }
  if (element.parentElement) element.parentElement.style.visibility = 'hidden';
  // element.style.visibility = 'hidden'
  // fabricCanvas.freeDrawingBrush = null
};

export const enableFabric = (fabricCanvas: fabric.Canvas): void => {
  const element = fabricCanvas.getElement();
  if (!element) {
    console.debug('No element', fabricCanvas);
    return;
  }
  if (element.parentElement) element.parentElement.style.visibility = 'visible';
  // element.style.visibility = 'visible'
  // fabricCanvas.freeDrawingBrush = { /* ... */ }
};

import { emitCustomEvent } from 'react-custom-events';
import { Area, CanvasDrawingData, CanvasDrawingOptions, CanvasDrawingToolsData, MessageData, Size } from './types';
import { CANVAS_STREAM_FRAME_RATE } from './constants';

export function createCanvasDrawingData(params: Partial<CanvasDrawingData> = {}): CanvasDrawingData {
  return {
    ...{
      // data: dataURL
      data: '',
      width: 0,
      height: 0,
      timestamp: Date.now(),
      mimeType: CANVAS_EXPORT_IMAGE_MIME_TYPE,
      quality: CANVAS_EXPORT_IMAGE_QUALITY,
    },
    ...params,
  };
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
 */
export async function saveCanvasAsFile(canvas: HTMLCanvasElement): Promise<void> {
  const MIME_TYPE = 'image/png';
  const QUALITY = 1;
  const blob = await new Promise<Blob>((resolve, reject) => {
    // image/png
    // image/jpeg 0.92
    // image/webp 0.80
    canvas.toBlob(
      blob => {
        if (blob) {
          resolve(blob);
        } else {
          reject('No blob');
        }
      },
      MIME_TYPE,
      QUALITY
    );
  });

  const fileName = `image-file`;
  download(blob, fileName);
}

/**
 * これは非対応です。
 * ArrayBuffer導入はデータ以外の項目をもつとややこしくなるため、非対応。
 * ArrayBufferを送信したほうがよいと決まったら、対応すること。
 */
const extractCanvasDrawingDataFromArrayBuffer = (buffer: ArrayBuffer) => {
  return { data: buffer };
};

export const onMessage = (data: MessageData): void => {
  console.debug('message received', data);
  if (data) {
    const rawCanvasDrawingData: Partial<CanvasDrawingData> =
      typeof data === 'string' ? JSON.parse(data) : extractCanvasDrawingDataFromArrayBuffer(data);
    const canvasDrawingData = createCanvasDrawingData(rawCanvasDrawingData);
    emitCustomEvent('drawing-data', canvasDrawingData);
  } else {
    throw new Error('Invalid message received');
  }
};

export const initializeCanvasDrawingOptions = (options: Partial<CanvasDrawingOptions> = {}): CanvasDrawingOptions => {
  return {
    ...{
      enableDrawing: true,
      enableRemoteDrawing: true,
      enableRemoteActions: true,
    },
    ...options,
  };
};

export function createCanvasDrawingToolsData(): CanvasDrawingToolsData {
  return {
    tool: 'line',
    lineColor: 'blue',

    // 内部
    lineWidth: 1,
    shadowColor: 'black',
    shadowWidth: 0,
    shadowOffset: 0,
  };
}

export function dataURLToImage(dataURL: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    // const image = new Image()
    const image = document.createElement('img') as HTMLImageElement;
    image.setAttribute('data-dataURLToImage', '');
    image.src = dataURL;
    image.addEventListener('load', () => {
      resolve(image);
    });
    image.addEventListener('error', reject);
  });
}

export function canvasToStream(canvas: HTMLCanvasElement): MediaStream {
  // const canvas = fabricjs.getElement()
  const captureStreamCanvas = canvas as HTMLCanvasElement & { captureStream: (frameRate?: number) => MediaStream };
  const stream =
    CANVAS_STREAM_FRAME_RATE !== undefined
      ? captureStreamCanvas.captureStream(CANVAS_STREAM_FRAME_RATE)
      : captureStreamCanvas.captureStream();
  return stream;
}

export function canvasToTrack(canvas: HTMLCanvasElement): MediaStreamTrack {
  const tracks = canvasToStream(canvas).getTracks(); // ONE TRACK
  return tracks[0];
}

export function mediaStreamToVideo(stream: MediaStream): HTMLVideoElement {
  const video = document.createElement('video');
  video.setAttribute('data-mediaStreamToVideo', '');
  video.srcObject = stream;
  video.autoplay = true;
  return video;
}

export function mediaStreamTrackToVideo(track: MediaStreamTrack): HTMLVideoElement {
  const stream = new MediaStream();
  stream.addTrack(track);
  return mediaStreamToVideo(stream);
}

/**
 * 現在は黒くするだけ。alphaのみをしてもいい。
 */
export function removeCanvasAlpha(canvas: HTMLCanvasElement): void {
  const ctx = canvas.getContext('2d', { alpha: false });
  if (!ctx) {
    return;
  }
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const newImageData = new ImageData(imageData.width, imageData.height);
  for (let i = 0; i < imageData.data.length; i += 4) {
    newImageData.data[i] = 0; // imageData.data[i];
    newImageData.data[i + 1] = 0; // imageData.data[i+1];
    newImageData.data[i + 2] = 0; // imageData.data[i+2];
    newImageData.data[i + 3] = 255; // a
  }
  ctx.putImageData(newImageData, 0, 0);
}

/**
 * DEBUG用
 */
export function watchCanvasAlpha(canvas: HTMLCanvasElement, tag = Math.random().toString()): number {
  return window.setInterval(() => {
    const ctx = canvas.getContext('2d', { alpha: false });
    if (!ctx) {
      return;
    }

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let alphaCount = 0;
    for (let i = 0; i < imageData.data.length; i += 4) {
      if (imageData.data[i + 3]) {
        alphaCount++;
      }
    }
    console.debug(`${tag}: Alpha count: ${alphaCount}`);
  }, 1000);
}

export function uniqueID(): string {
  return uuidv1();
}

/**
 * ベースプログラムは通常次の形式を使用している：`camera-${Date.now()}`
 */
export function createTrackName(prefix: string): string {
  return `${prefix}${uniqueID()}`;
}

export function createTestAlphaCanvas(): HTMLCanvasElement {
  const canvas = document.createElement('canvas');
  canvas.setAttribute('data-createTestAlphaCanvas', '');
  const ctx = canvas.getContext('2d', {
    alpha: false,
  });
  if (!ctx) {
    throw new Error('No context');
  }

  // removeCanvasAlpha(canvas)

  setInterval(() => {
    const x = Math.random() * canvas.width;
    const y = Math.random() * canvas.height;
    const width = Math.random() * canvas.width;
    const height = Math.random() * canvas.height;
    ctx.fillStyle = Math.random() > 0.5 ? 'red' : 'blue';
    ctx.fillRect(x, y, width, height);
  }, 1000);

  // DOM(必須?)
  canvas.style.position = 'absolute';
  canvas.style.left = '-9999px';
  document.body.appendChild(canvas);

  return canvas;
}

/**
 * Canvasをclone
 * destinationContextOptionsによりalphaなどを設定できる。
 */
export function createMirroredCanvas(
  sourceCanvas: HTMLCanvasElement,
  destinationContextOptions = {}
): HTMLCanvasElement {
  const capturableSourceCanvas = sourceCanvas as HTMLCanvasElement & {
    captureStream: (frameRate?: number) => MediaStream;
  };
  // const stream = capturableSourceCanvas.captureStream()
  const stream = capturableSourceCanvas.captureStream(15);
  const video = mediaStreamToVideo(stream);

  const destinationCanvas = document.createElement('canvas');
  destinationCanvas.setAttribute('data-createMirroredCanvas', '');
  const destinationCtx = destinationCanvas.getContext(
    '2d',
    destinationContextOptions
  ) as CanvasRenderingContext2D | null;
  if (!destinationCtx) {
    throw new Error('no context');
  }
  // document.body.appendChild(destinationCanvas)

  // TEST
  destinationCtx.globalAlpha = 0;
  watchCanvasAlpha(destinationCanvas, 'mirrored-canvas');

  const round = () => {
    // Auto-Resize
    if (sourceCanvas.width !== destinationCanvas.width || sourceCanvas.height !== destinationCanvas.height) {
      destinationCanvas.width = sourceCanvas.width;
      destinationCanvas.height = sourceCanvas.height;
    }

    destinationCtx.drawImage(video, 0, 0, destinationCanvas.width, destinationCanvas.height); // Stretchを確認!!

    // Loop
    requestAnimationFrame(round); // requestAnimationFrame
    // setInterval: NOTHING
  };
  requestAnimationFrame(round);
  // setInterval(round, 1000 / 20)

  return destinationCanvas;
}

/**
 * 元の面積(x, y)を対象の面積に合わせる。
 */
export function normalizeArea(sourceArea: Area, destinationSize: Size): Area {
  // s(50), d(100). x 5 => d 10, so factor = 2, => 100 / 50, => d / s
  const xFactor = destinationSize.width / sourceArea.width;
  const yFactor = destinationSize.height / sourceArea.height;

  const normalizedArea: Area = {
    x: sourceArea.x * xFactor,
    y: sourceArea.y * yFactor,
    width: destinationSize.width,
    height: destinationSize.height,
  };

  return normalizedArea;
}

export function createArea(options: Partial<Area> = {}): Area {
  return {
    x: options.x || 0,
    y: options.y || 0,
    width: options.width || 0,
    height: options.height || 0,
  };
}

/*
TEST用：

 function canvasToTrack(canvas) {
  const stream = canvas.captureStream(10)
  const tracks = stream.getTracks() // ONE TRACK
  return tracks[0]
}

function mediaStreamToVideo(stream) {
  const video = document.createElement('video')
  video.srcObject = stream
  video.autoplay = true
  return video
}

function mediaStreamTrackToVideo(track) {
  const stream = new MediaStream()
  stream.addTrack(track)
  return mediaStreamToVideo(stream)
}

// "trackSubscribed"
// temp1 = MediaStreamTrack
document.body.appendChild(mediaStreamTrackToVideo(temp1))

*/

export const applySizeToElementStyle = (size: Size, element: HTMLElement): void => {
  element.style.left = `{size.x}px`;
  element.style.top = `{size.y}px`;
  element.style.width = `{size.width}px`;
  element.style.height = `{size.height}px`;
};

export const containChildInParent = (childSize: Size, parentSize: Size): Area => {
  return contain(parentSize.width, parentSize.height, childSize.width, childSize.height);
};

/**
 * ※videoのサイズ・リサイズにより、canvas, iCanvasのリサイズを検討すべき。
 */
export const videoToCanvas = (
  video: HTMLVideoElement,
  options: {
    interval?: number;
    onFrame?: (c: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => HTMLCanvasElement;
  } = {}
): { canvas: HTMLCanvasElement; stop: () => void } => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('No context');
  }

  const iCanvas = document.createElement('canvas');
  const iCtx = iCanvas.getContext('2d');
  if (!iCtx) {
    throw new Error('No context');
  }

  const interval = options.interval || 20;
  const onFrame =
    options.onFrame ||
    function(c: HTMLCanvasElement): HTMLCanvasElement {
      return c;
    };

  const round = () => {
    iCtx.drawImage(video, 0, 0, iCanvas.width, iCanvas.height);
    const c = onFrame(iCanvas, iCtx);
    ctx.drawImage(c, 0, 0, canvas.width, canvas.height);
  };
  const i = window.setInterval(round, interval);
  const stop = () => clearInterval(i);

  return {
    canvas,
    stop,
  };
};

export const makeColorAlphaInCanvas = (
  ctx: CanvasRenderingContext2D,
  pixelChecker: (p: { r: number; g: number; b: number; a: number }) => boolean
): void => {
  const canvas = ctx.canvas;
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const d = imageData.data;
  for (let i = 0; i < d.length; i += 4) {
    const pixel = {
      r: d[i],
      g: d[i + 1],
      b: d[i + 2],
      a: d[i + 3],
    };
    if (pixelChecker(pixel)) {
      d[i + 3] = 0;
    }
  }
  ctx.putImageData(imageData, 0, 0);
};
