import React, { CSSProperties, FC, useRef, useEffect, useCallback } from 'react';
import { PlotEvent, DrawMode } from '../../types';
import { useStrokeState } from '../../hooks/useStrokeState/useStrokeState';
import UseResizeObserver from '../../hooks/useResizeObserver/useResizeObserver';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { RemoteParticipant, RemoteDataTrack } from 'twilio-video';
import { useAppState } from '../../state';

export type MirroringContainerAttribute = {
  className?: string;
  style?: CSSProperties;
  width: number;
  height: number;
};

type Point = {
  x: number;
  y: number;
};

const MirroringContainer: FC<MirroringContainerAttribute> = props => {
  const canvasRef = useRef(null);
  const handleResize = () => {
    // リサイズ時の再描画
    resized(strokeListener);
  };
  UseResizeObserver([canvasRef], handleResize);
  const { whoIs } = useAppState();
  const {
    addPlotEventListener,
    removePlotEventListener,
    addStrokeListener,
    removeStrokeListener,
    resized,
    offsetX,
    offsetY,
    addPlotEvent,
  } = useStrokeState();
  const { screenScale } = useStrokeState();
  const { room } = useVideoContext();
  //const [dataTrack, setDataTrack] = useState<LocalDataTrack | undefined>(undefined);
  const lastPlotPoints = new Map<string, Point>();

  const getContext = (lineWidth?: number, lineCap?: CanvasLineCap, lineColor?: string, drawMode?: DrawMode) => {
    const currentCanvas = canvasRef?.current;
    if (currentCanvas) {
      const ctx = (currentCanvas as HTMLCanvasElement).getContext('2d') as CanvasRenderingContext2D;
      if (drawMode === 'pen') {
        ctx.globalCompositeOperation = 'source-over';
        ctx.lineWidth = lineWidth ?? 1;
        ctx.lineCap = lineCap ?? 'round';
        ctx.strokeStyle = lineColor ?? 'black';
      } else if (drawMode === 'eraser') {
        ctx.globalCompositeOperation = 'destination-out';
        ctx.lineWidth = 20 * screenScale;
        ctx.lineCap = 'round';
      }
      return ctx;
    } else {
      return null;
    }
  };

  const onTrackMessage = useCallback(
    (data: string | ArrayBuffer, track: RemoteDataTrack, participant: RemoteParticipant) => {
      track;
      participant;
      if (typeof data === 'string') {
        const plotEvent = JSON.parse(data);
        if (plotEvent.userName === whoIs) {
          // 自分のデータは破棄
        } else {
          if (plotEvent.length === undefined) {
            addPlotEvent([plotEvent]);
          } else {
            addPlotEvent(plotEvent);
          }
        }
      }
    },
    [addPlotEvent, whoIs]
  );

  useEffect(() => {
    console.debug('Track room.on(trackSubscribed)');
    if (!room) {
      console.debug('No room');
      return;
    }
    room.on('trackMessage', onTrackMessage);
    //room.on('trackSubscribed', onTrackSubscribed);
    //room.on('trackPublished', onTrackPublished);

    return () => {
      room.off('trackMessage', onTrackMessage);
      //room.off('trackSubscribed', onTrackSubscribed);
      //room.off('trackPublished', onTrackPublished);
    };
  }, [onTrackMessage, room]);

  // 初期化
  useEffect(() => {
    addPlotEventListener(mouseEventListener);
    addStrokeListener(strokeListener);

    return () => {
      removePlotEventListener(mouseEventListener);
      removeStrokeListener(strokeListener);
    };
  });

  /*useEffect(() => {
    // 今ONになった
    if (isSharingScreen) {
      setDataTrack(localTracks.filter(t => t.name === 'data')[0] as LocalDataTrack | undefined);
    } else {
      setDataTrack(undefined);
    }
  }, [isSharingScreen, localTracks, setDataTrack]);*/

  const beginDrawing = (e: PlotEvent) => {
    const { userName, x, y, lineWidth, lineCap, lineColor, targetWidth, targetHeight, drawMode } = e;
    const ratioW = props.width / (targetWidth ?? props.width);
    const ratioH = props.height / (targetHeight ?? props.height);
    const ratio = ratioW < ratioH ? ratioW : ratioH;
    const lineWidthScaled = (lineWidth ?? 5) * screenScale;
    const marginLeft = offsetX;
    const marginTop = offsetY;
    const ctx = getContext(lineWidthScaled, lineCap, lineColor, drawMode);
    const realX = (x ?? 1) * ratio + marginLeft;
    const realY = (y ?? 1) * ratio + marginTop;
    if (ctx && realX && realY) {
      ctx.beginPath();
      ctx.moveTo(realX, realY);
      lastPlotPoints.set(userName, { x: realX, y: realY });
    }
  };

  const continueDrawing = (e: PlotEvent) => {
    const { userName, x, y, lineWidth, lineCap, lineColor, targetWidth, targetHeight, drawMode } = e;
    const ratioW = props.width / (targetWidth ?? props.width);
    const ratioH = props.height / (targetHeight ?? props.height);
    const ratio = ratioW < ratioH ? ratioW : ratioH;
    const lineWidthScaled = (lineWidth ?? 5) * screenScale;
    const marginLeft = offsetX;
    const marginTop = offsetY;
    const ctx = getContext(lineWidthScaled, lineCap, lineColor, drawMode);
    const realX = (x ?? 1) * ratio + marginLeft;
    const realY = (y ?? 1) * ratio + marginTop;
    if (ctx && realX && realY) {
      if (lastPlotPoints.has(userName)) {
        const point = lastPlotPoints.get(userName);
        if (point?.x && point?.y) {
          ctx.beginPath();
          ctx.strokeStyle = lineColor ?? '#000000'; // beginPath後の指定でMacのSafariの色不定問題を解決
          ctx.moveTo(point.x, point.y);
        }
      }
      ctx.lineTo(realX, realY);
      lastPlotPoints.set(userName, { x: realX, y: realY });
      ctx.stroke();
    }
  };

  // 描画終了ごとにデータを作ると処理が重すぎて動きがカクカクになる
  const endDrawing = () => {
    //setDrawing(false);
    //if (props.onUpdateCanvas) props.onUpdateCanvas(canvasRef);
  };

  const reset = () => {
    const ctx = getContext(0, 'round', '#000000', 'pen');
    if (ctx) ctx.clearRect(0, 0, props.width, props.height);
  };

  const mouseEventListener = (mouseEvent: PlotEvent) => {
    if (mouseEvent) {
      switch (mouseEvent.action) {
        case 'begin':
          beginDrawing(mouseEvent);
          break;
        case 'continue':
          continueDrawing(mouseEvent);
          break;
        case 'end':
          endDrawing();
          break;
        default:
          break;
      }
    }
  };

  const strokeListener = (stroke: PlotEvent[]) => {
    // 全クリア再描画
    reset();
    for (const event of stroke) {
      if (!(event.undid ?? false)) {
        switch (event.action) {
          case 'begin':
            beginDrawing(event);
            break;
          case 'continue':
            continueDrawing(event);
            break;
          case 'end':
            endDrawing();
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <canvas
      className={props.className}
      style={{ ...props.style, cursor: 'crosshair' }}
      ref={canvasRef}
      width={props.width}
      height={props.height}
    />
  );
};

export default React.memo(MirroringContainer);
