// import
import { PopoverOrigin } from '@material-ui/core';
import Video from 'twilio-video';
import { CanvasDrawingDataType } from './types';

// Twilio
export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';

// 新規
// ZINDEX
// MuiPopover-root zindex = 1300。絶対これを超えないこと。
const CANVAS = 30;
const CANVAS_OVERLAY = CANVAS + 1;
const CUSTOM_OVERLAY_1 = CANVAS_OVERLAY + 1;
const LAST_CANVAS_OVERLAY = 39;
export const ZINDEX = {
  AVATAR_CONTAINER: 1,
  IDENTITY_CONTAINER: 1,
  RECONNECTING_CONTAINER: 1,
  PARTICIPANT_LIST_CONTAINER: 5,
  SCREEN_SHARE_BANNER: LAST_CANVAS_OVERLAY + 1,
  CHAT_WINDOW_CONTAINER: 9,
  CHAT_WINDOW_MOBILE_CONTAINER: 100,
  MESSAGE_LIST_SCROLL_CONTAINER_BUTTON: 100,
  MENU_BAR_CONTAINER: LAST_CANVAS_OVERLAY + 1,
  INFO_CONTAINER: LAST_CANVAS_OVERLAY + 1,
  VIDEO: 20,
  CANVAS,
  CANVAS_OVERLAY,
  CUSTOM_OVERLAY_1,
  VIDEO_LIST: 40,
  TOOLS: 50,
};

// Styles
export const INSIDE_VIDEO_STYLES = {
  width: '100%',
  height: '100%',
  margin: 'auto' /* display: 'flex', flexDirection: 'column' as const, alignItems: 'center' */,
} as const;
const CANVAS_FULL_SIZE = true;
const CANVAS_FULL_SIZES = {
  minWidth: '100%',
  minHeight: '100%',
} as const;
export const CANVAS_STYLES = {
  canvasWrapper: {
    position: 'absolute',
    cursor: 'pointer',
    // top: 0,
    // left: 0,
    // CENTERING
    // OLD:
    // transform: 'translate(-50%, 0)',
    // left: '50%',
    // right: 0,
    // NEW:
    left: '0',
    right: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    // VERTICAL CENTERING
    top: '50%',
    transform: 'translateY(-50%)',
    // DEFAULT = 100%. サイズ変更は後で上書き。
    width: '100%',
    height: '100%',
    //
    zIndex: ZINDEX.CANVAS,
    '& .canvas-container': {
      margin: 'auto',
      maxHeight: '100%',
      ...(CANVAS_FULL_SIZE ? CANVAS_FULL_SIZES : {}),
      '& canvas': {
        maxHeight: '100%',
        ...(CANVAS_FULL_SIZE ? CANVAS_FULL_SIZES : {}),
      },
    },
  },
  videoOverlay: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: 1,
    backgroundColor: 'rgba(0,0,0,.75)',
    color: '#fff',
    padding: '28px',
  },
  videoWrapper: {
    backgroundColor: 'black',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    position: 'relative',
    '& video': {
      margin: '0 auto',
      // width, height 両方ともなしか100%でもいい。
    },
  },
  canvasTrackWrapper: {
    // border: '5px solid blue', // DEBUG用
    position: 'absolute',
    width: '100%',
    height: '100%',
    '& canvas': {
      margin: '0 auto',
      objectFit: 'contain',
      // margin: 'auto',
      width: '100%',
      position: 'absolute',
      height: '100%',
      top: 0,
      left: 0,
    },
  },
} as const;

export const PALETTE = { 青: 'blue' as const, 黄: 'yellow' as const, 赤: 'red' as const };

/*
以下の二つを利用することで対象の「anchor element」の上中央に表示切り替えができます。
*/
export const ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};
export const TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

export const popoverStyle = { width: '700px', maxWidth: '90%' };
export const popOverProps = { style: popoverStyle };

/*
const useStyles = makeStyles({
    root: { width: '100%', height: '100%', overflow: 'hidden', border: '1px solid black', position: 'relative', backgroundColor: 'lightgray' },
    videoWrapper: { width: '100%', height: '100%' },
    canvasDrawingWrapper: { position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' },
    // drawing: { ...INSIDE_VIDEO_STYLES }
})
*/
export const VIDEO_STYLES = {
  /*
  contain: 特別処理が必要で、比率が保持されるが、小さくなり、バグが入りやすい。
  cover: 上下が切れるバグがあるよう。
  fill: coverのよりよい方法? 問題があったので、containがいい？
  unset: なし。これでもいけるよう。多分ほかのところで100％を利用しているから。
  */
  // objectFit: 'fill' as const // OK
  objectFit: 'contain' as const, // TEST
} as const;
/*
const VIDEO_STYLES = {
    width: '100%',
    height: '100%',
    maxHeight: '-webkit-fill-available',
    objectFit: 'cover'
}
*/
/*
const css = `
.canvas-container {
    width: 100%!important;
    height: 100%!important;
}
canvas {
    width: 100%!important;
    height: 100%!important;
    object-fit: cover;
}
`
*/
/**
 * 速くVideoを取得する必要があれば。
 * Room => ParticipantList => ParticipantTracks => Publication => VideoTrack => Video(video)
 */

export const TRACKS = {
  CANVAS: {
    prefix: 'canvas', // canvas OR video-canvas
  },
  // DATA
} as const;

/**
 * @see https://media.twiliocdn.com/sdk/js/video/releases/1.14.1/docs/global.html#ConnectOptions
 */
export const ADDITIONAL_VIDEO_CONNECT_OPTIONS: Video.ConnectOptions = {
  // maxAudioBitrate
  // maxVideoBitrate
  // preferredVideoCodecs: [], // ['H264', 'VP8', 'VP9'] // GroupRoomは特別処理が必要のようです。
  // preferredAudioCodecs: [], // ['isac, 'opus', 'PCMA', 'PCMU']
  /*
  ...{
    chromeSpecificConstraints: {
      mandatory: {
        // Enable CPU overuse detection
        googCpuOveruseDetection: true,
        // Not sure about this, but Google Hangouts uses it
        googCpuOveruseEncodeUsage: true,
        // The CPU % threshold for overuse
        googCpuOveruseThreshold: 85
      }
    }
  } as any
  */
};

export const SCREENSHARE_CONSTRAINTS: MediaStreamConstraints = {
  audio: false,
  video: {
    frameRate: 10,
    height: 1080,
    width: 1920,
    // sampleRate
    // sampleSize
  },
} as const;

export const SCREENSHARE_TWILIO_TRACK_SETTINGS = {
  name: 'screen', // Tracks can be named to easily find them later
  priority: 'low', // Priority is set to high by the subscriber when the video track is rendered
} as const;

// Participant
export const PARTICIPANT_MAX_TRACKS = 2; // Video, Screen
export const SHOW_ALL_PARTICIPANTS_IN_LIST = true;
export const PARTICIPANT_LIST_ALLOWED_TRACKS = ['camera', 'screen', 'audio'];
export const PARTICIPANT_LIST_PRIORITIZED_TRACK = 'camera'; // 'screen' // screen, camera
export const PARTICIPANT_USE_AVATAR = false;

// Env
export const debug = process.env.REACT_APP_DEBUG === 'true' ? true : false;
export const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:3003';
export const finishedUrl = process.env.REACT_APP_FINISHED_URL || '/';
export const routes = {
  room:
    process.env.REACT_APP_SERVER_LANGUAGE === 'php'
      ? (id: string) => `/server.php?route=room&roomId=${id}`
      : (id: string) => `/room/${id}`,
};

// Old settings
export const autoCamera = true;
export const autoScreen = false;

// 以下は更新された設定
// export const DEFAULT_CANVAS_EXPORT_IMAGE_MIME_TYPE = 'image/png'
export const DEFAULT_CANVAS_EXPORT_IMAGE_MIME_TYPE = 'image/webp'; // 一番小さい。テストのために利用。
// export const DEFAULT_CANVAS_EXPORT_IMAGE_QUALITY = 0.9
export const DEFAULT_CANVAS_EXPORT_IMAGE_QUALITY = 0.1; // テストのために利用。Twilioにより16kib制限があるようのため：https://www.twilio.com/docs/video/javascript-v1-using-the-datatrack-api#configuring-datatrack-reliability
export const CANVAS_EXPORT_IMAGE_MIME_TYPE = DEFAULT_CANVAS_EXPORT_IMAGE_MIME_TYPE; // 送信する描き画像のタイプ
export const CANVAS_EXPORT_IMAGE_QUALITY = DEFAULT_CANVAS_EXPORT_IMAGE_QUALITY; // 送信する描き画像の画質：0 ~ 1

// 内部処理
export const CANVAS_WIDTH = 640;
export const CANVAS_HEIGHT = 480;

export const CANVAS_MODIFIED_EVENTS = [
  // GENERAL
  // 'after:render',
  'canvas:cleared', // クリア

  // OBJECT
  'object:added',
  'object:modified',
  'object:removed',

  // ACTION
  'moved',
  'scaled',
  'rotated',
  'skewed',

  // TOOLS
  'text:changed', // テキスト
  'path:created', // 線
];
export const CANVAS_MODIFIED_DELAY = 100;

export const TEXT_PLACE_HOLDER = 'TEST TEXT';
export const TEXT_FONT_SIZE = 20;
export const TEXT_WIDTH = 200;

export const DEFAULT_MEDIA_STREAM_CONSTRAINTS = { video: true, audio: true };

interface VideoDisplaySetting {
  video: ('remote-camera' | 'local-camera' | 'local-screen')[];
  opacity: number;
}
interface VideoDisplaySettings {
  main: VideoDisplaySetting;
  leftOverlay: VideoDisplaySetting;
  rightOverlay: VideoDisplaySetting;
}
/**
 * main: 大きな画面
 * leftOverlay: mainの上に表示する左画面
 * rightOverlay: mainの上に表示する右画面
 *
 * video: 次を含む配列（優先順位: 高い => 低い）：'remote-camera': 相手の映像, 'local-camera': 自分の映像, 'local-screen': 自分のカメラ
 * opacity: (透明) 0 ~ 1 (不透明)
 */
export const VIDEO_DISPLAY_SETTINGS: VideoDisplaySettings = {
  main: {
    // video: ['local-screen', 'remote-camera'],
    video: ['remote-camera'],
    opacity: 1,
  },
  leftOverlay: {
    video: ['local-camera'],
    opacity: 0.7,
  },
  rightOverlay: {
    // video: [],
    video: ['local-screen'],
    opacity: 0.7,
  },
};
export const VIDEO_OVERLAY_SETTINGS = {
  marginTop: '10%',
  marginSide: '10%',
  width: '15%',
  height: '30%',
};
export const PRIORITIZED_REMOTE_VIDEO_TRACK = 'screen-video'; // 'screen-video' | 'camera-video' 相手の映像トラックはこれの場合、ほかのトラックがあっても、絶対表示させる。

// canvas: 描き表示の有効・無効。描きをすると、相手へデータが送信される。
// image: 相手から取得した描き画像データの有効・無効。
export const VIDEO_TYPE_SETTINGS = {
  'remote-camera': {
    canvas: true,
    image: false,
  },
  'local-camera': {
    canvas: false,
    image: true,
  },
  'local-screen': {
    // canvas: true,
    canvas: false,
    // image: false,
    image: true,
  },
};

export const TRACK_ATTRIBUTE = 'data-track';
export const IGNORE_MODIFIED_EVENT_AFTER_RECEIVED_DATA_DURATION = 500;
export const ROOM_END_TIME_POLL_INTERVAL = 5000;

// 自動接続問題を強制的になおす。必要の場合のみ有効にする：
export const AUTO_WAIT_FOR_REMOTE_VIDEO_ON_CONNECT = true;
export const AUTO_WAIT_FOR_REMOTE_VIDEO_ON_CONNECT_CHECK_INTERVAL = 2000;

/*
console.debug('settings debug', {
  debug, serverUrl, finishedUrl
})
*/

// export const TRACK_NAMES = ['camera-video', 'camera-audio', 'screen-video', 'data'] as const // OLD
export const TRACK_NAMES = ['data'] as const;
export type TrackName = typeof TRACK_NAMES[number];

export const DATA_TYPE: CanvasDrawingDataType = 'dataURL'; // "arrayBuffer" | "dataURL" | "ImageData"

// 内部処理方法のフラグ一覧
export const SOURCE_OUTPUT_METHOD: 'drawImage' | 'fabric.add' = 'drawImage' as const; // fabric.addはステート管理があるため推奨。
export const IMAGE_DATA_OUTPUT_METHOD: 'drawable' | 'source' = 'source' as const;
export const CANVAS_STREAM_FRAME_RATE = 10; // undefined = frameRateなし
export const CANVAS_GLOBAL_ALPHA = 1; // 0 = 透明, 1 = 不透明
export const CANVAS_STREAM_TYPE: 'canvas' | 'test' | 'mirror' = 'mirror';
export const ALLOW_LOCAL_CANVAS_STREAM = true;
export const CHANGE_CANVAS_TRACK_BLACK_TO_ALPHA = true;
export const RECEIVED_DATA_AREA_NORMALIZATION_METHOD: 'scale' | 'maximize' | undefined = 'maximize';
export const USE_MULTIPLE_TRACKS_FOR_MERGED_SCREENSHARE_CANVAS = false;
export const HIDE_OWN_CANVAS_WHEN_SHARING_SCREENSHARE_CANVAS = true;

// RESIZE
export const CANVAS_RESIZING_ENABLED = true;
export const SET_ASPECT_RATIO = true;
export const CALCULATE_INITIAL_FABRIC_SIZE = false;
export const RESIZE_DELAY = 50;
export const AUTO_RESIZE_CANVAS_SIZE = false;
export const AUTO_RESIZE_CANVAS_STYLE_SIZE = true;
export const AUTO_RESIZE_CANVAS_WRAPPER = true;
export const RERENDER_CANVAS_ON_AUTO_RESIZE = true;
export const ENSURE_FULL_SIZE_ON_FABRIC_JS_ELEMENTS = true;
export const USE_AUTO_RESIZE_INTERVAL = false;
export const AUTO_RESIZE_INTERVAL = 1000;
export const RESIZE_ON_DATA_RECEIVED = false;
export const USE_VIDEO_CHECK_RESIZE = true;
export const DEFAULT_CAMERA_SIZE = { width: 914, height: 738 }; // TEST用
export const DEFAULT_ASPECT_RATIO = DEFAULT_CAMERA_SIZE.width / DEFAULT_CAMERA_SIZE.height; // TEST用

// 重要フラグ
export const DRAW_RECEIVED_DATA = true;
export const CLEAR_ON_END = false;
export const SAVE_CANVAS_ON_END = true;
export const USE_CANVAS_FOR_CANVAS_TRACK = true;
export const ENABLE_CANVAS_TRACK = true;
export const CANVAS_ENABLED = true; // 描き機能
export const PREVENT_DUPLICATE_AUDIO_TRACKS = true;

// データ送信手段。一つのみを有効にすること。
export const CREATE_CANVAS_STREAM = false; // SEND_DATA と一緒にできない
export const SEND_DATA = true; // CREATE_CANVAS_STREAM と一緒にできない
export const MERGE_CANVAS_INTO_SCREENSHARE = false;

// Main participant
// camera, screen, audio
export const MAIN_PARTICIPANT_ALLOWED_TRACKS = ['camera', 'screen', 'audio'];
export const MAIN_PARTICIPANT_UNIQUE_TRACKS = [
  // 各配列は一緒にできないTrackのkey
  ['camera', 'screen'],
];
export const MAIN_PARTICIPANT_PRIORITIZED_TRACK = 'screen';
export const MAIN_PARTICIPANT_MAX_TRACKS = 3; // video + audio

export const TWILIO_CONSOLE_LEVEL: Video.Log.LogLevelDesc = 'warn'; // テスト用 // "trace" | "debug" |

export const DISABLE_CONSOLE_METHODS = ['debug', 'log', 'info', 'warn', 'error'];
DISABLE_CONSOLE_METHODS.forEach((m: any) => {
  const c = window.console as any;
  if (c && c[m]) {
    const oldRef = c[m];
    //c[m] = () => { // NO METHODS
    c[m] = (...args: any[]) => {
      // HANDLE SPECIFIC
      const isTwilioLog = (args: any[]) =>
        typeof args[0] === 'string' &&
        typeof args[1] === 'string' &&
        ['trace', 'debug', 'info', 'warn', 'error'].includes(args[1]);
      if (isTwilioLog(args)) {
        oldRef(...args);
      }
    };
  }
});

export const MAX_LISTENERS = 4;
