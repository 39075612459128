import { Room } from 'twilio-video';
import { WEB_SOCKET_HOST } from '../constants';
import { requestChatEnter, requestChatLeave, requestChatSend, sendMessage } from './httpClient';
import { Conversation } from '@twilio/conversations';
import { EventEmitter } from 'events';
import useRoomState from '../../hooks/useRoomState/useRoomState';

const WEB_SOCKET_STATE = {
  NON: 0,
  CONNECTED: 1,
  CLOSED: 2,
};

// クラスなのでステートが使えないことに注意
export default class WebSocketClient {
  private static instance: WebSocketClient | undefined;
  private static state: number;
  private static sessionId: string | undefined;
  private static socket: WebSocket | undefined;
  private static room: Room | undefined;
  private static logs: string[];
  private static timerHandle: NodeJS.Timeout | undefined;

  private constructor() {
    WebSocketClient.state = 0;
  }

  static async create(token: string): Promise<WebSocketClient> {
    token;
    if (WebSocketClient.instance) {
      await WebSocketClient.disconnect();
    }
    const S = '0123456789';
    const N = 50;
    const short_token = Array.from(Array(N))
      .map(() => S[Math.floor(Math.random() * S.length)])
      .join('');
    this.sessionId = short_token;
    WebSocketClient.instance = new WebSocketClient();
    try {
      const url = WEB_SOCKET_HOST + '/connect?sessionId=' + short_token;
      this.socket = new WebSocket(url);
    } catch (e) {
      throw new Error('There was a problem connecting to My conversation service.');
    }
    this.state = WEB_SOCKET_STATE.CONNECTED;
    // 以降定期的にデータを送ってkeepalive - 実環境にて1分程度で切られるようなので30秒
    this.timerHandle = setInterval(() => {
      const commonAction = () => {
        if (this.state === WEB_SOCKET_STATE.CONNECTED) {
          this.socket?.send(' ');
        } else if (this.state === WEB_SOCKET_STATE.CLOSED) {
          // 会議画面が表示されている間は切断されていても再接続を試みる
          try {
            const url = WEB_SOCKET_HOST + '/connect?sessionId=' + short_token;
            this.socket = new WebSocket(url);
          } catch (e) {
            throw new Error('There was a problem connecting to My conversation service.');
          }
          this.state = WEB_SOCKET_STATE.CONNECTED;
        }
      };

      if (this.state === WEB_SOCKET_STATE.CONNECTED) {
        // IntroContainerが見える状態であれば、タイマーを止める
        const introShowing = document.getElementById('introShowing');
        // モジュールがある
        if (introShowing) {
          const isDisplayBlock = introShowing.style.display === 'block';
          // 見えている
          if (isDisplayBlock) {
            if (this.timerHandle) {
              clearInterval(this.timerHandle);
              this.timerHandle = undefined;
            }
            this.disconnect();
          } else {
            // 見えていない
            commonAction();
          }
        } else {
          // モジュールがない - 見えていない
          commonAction();
        }
      }
    }, 30000);
    return WebSocketClient.instance;
  }

  static async disconnect() {
    // ソケットが開かれているかどうかに関わらずタイマーは止める
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
      this.timerHandle = undefined;
    }
    if (this.socket) {
      console.log('disconnect ....');
      if (this.room && this.sessionId && this.state === WEB_SOCKET_STATE.CONNECTED) {
        await requestChatLeave(this.room.sid, this.sessionId);
      }
      this.sessionId = undefined;
      this.room = undefined;
      this.logs = [];
      this.socket = undefined;
      WebSocketClient.instance = undefined;
    }
  }

  async getConversationByUniqueName(room: Room): Promise<Conversation> {
    // ルーム入室
    const res = await requestChatEnter(room.sid, WebSocketClient.sessionId || '');
    WebSocketClient.room = room;
    WebSocketClient.logs = res.logs;
    const app_id = localStorage.getItem('appId') || '';
    const authorized_key = localStorage.getItem('authorizedKey') || '';
    const staff_alias = localStorage.getItem('staffId') || '';
    const room_id = localStorage.getItem('roomName') || '';

    // チャットイベント設定
    const conversation: any = new EventEmitter();
    // 不要
    conversation.getMessages = async () => {
      return {
        items: WebSocketClient.logs.map(data => {
          const message = JSON.parse(data);
          return Object.assign(message, {
            dateCreated: new Date(message.created * 1000),
          });
        }),
      };
    };
    conversation.sendMessage = async (message: string) => {
      if (!WebSocketClient.room || !WebSocketClient.sessionId || WebSocketClient.state !== WEB_SOCKET_STATE.CONNECTED) {
        return;
      }
      const author = WebSocketClient.room.localParticipant.identity;
      if (authorized_key && app_id) {
        try {
          // JSONパースできるならファイル扱い
          // 元のコードは正しくファイル添付を検出できていないので修正
          const json = JSON.parse(message);
          // FSMでnlp v3側にも投稿
          // d2dの場合ファイル名のみ記録
          sendMessage(
            room_id,
            {
              member_alias: staff_alias,
              member_type: 1,
              type: 'media',
              text: json.filename,
              data: JSON.stringify({ filename: json.filename, contentType: json.contentType }),
            },
            authorized_key,
            app_id
          );
        } catch (error) {
          // FSMでnlp v3側にも投稿
          sendMessage(
            room_id,
            {
              member_alias: staff_alias,
              member_type: 1,
              type: 'text',
              text: message,
            },
            authorized_key,
            app_id
          );
        }
        // 旧APIへの送信
        const type = 'text';
        console.log('requestChatSend', message);
        await requestChatSend(WebSocketClient.room?.sid, WebSocketClient.sessionId, author, message, type);
      }
    };

    // WebSocketイベント設定
    // ここをpusherへ入れ替え
    if (WebSocketClient.socket) {
      WebSocketClient.socket.onmessage = event => {
        // FSM+Pusher実装時はここでFSMのgetMessages
        // FSMのメッセージとTwilioのチャットコンポーネントのデータ型が異なるので
        // ここでオブジェクトの変換を入れる
        // typeがtextの場合とmediaの場合で型が異なる
        // それぞれMessageListの実装を参照
        // typeがmemoの場合ここで除外する
        const data = JSON.parse(event.data);
        if (data.created) {
          conversation.emit(
            'messageAdded',
            Object.assign(data, {
              dateCreated: new Date(data.created * 1000),
            })
          );
        }
      };
      WebSocketClient.socket.onclose = () => {
        WebSocketClient.state = WEB_SOCKET_STATE.CLOSED;
        conversation.emit('socketClosed', {});
      };
      WebSocketClient.socket.onerror = () => {
        // エラーが発生したからと言ってソケットが自律閉鎖するとは限らない
        //WebSocketClient.state = WEB_SOCKET_STATE.CLOSED;
        conversation.emit('socketError', {});
      };
    }
    return conversation;
  }
}
