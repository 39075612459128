import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
  finishStep,
}

export default function PreJoinScreens(props: { onUpdateRoomAndUser?: (room: string, name: string) => void }) {
  // room_id:string, user_name:string
  const { user } = useAppState();
  const { getAudioAndVideoTracks, getLocalDataTrack } = useVideoContext();
  // 要求に合わせてこうしたのかもしれないけど、ホントは↓の行が正しい
  //const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const { URLRoomName } = useParams();
  const [step, setStep] = useState(Steps.roomNameStep);
  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [mediaError, setMediaError] = useState<Error>();
  const [initialized, setInitialized] = useState(false);

  const url = new URL(window.location.href);
  const params = url.searchParams;

  const roomId: string | null = params.get('room_id');
  const userName: string | null = params.get('user_name');
  const staffId: string | null = params.get('staff_id');
  const redirectUrl: string | null = params.get('redirect_url');
  //各サービス用パラメタ
  const serviceAlias: string | null = params.get('service_alias');
  const authorizedKey: string | null = params.get('authorized_key');
  const appId: string | null = params.get('app_id');

  // staffIdとroomNameはチャット送信で利用する。
  useEffect(() => {
    localStorage.setItem('staffId', staffId ?? '');
    localStorage.setItem('roomName', roomId ?? '');
    if (roomId) {
      setRoomName(roomId);
      if (userName) {
        setName(userName);
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [roomId, userName]);

  // 各サービスとのやり取りに利用する。
  useEffect(() => {
    if (appId) {
      localStorage.setItem('appId', appId);
    }
    if (authorizedKey) {
      localStorage.setItem('authorizedKey', authorizedKey);
    }
    if (serviceAlias) {
      localStorage.setItem('serviceAlias', serviceAlias);
    }
  }, [appId, authorizedKey, serviceAlias]);

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks()
        .catch(error => {
          console.debug('Error acquiring local media:');
          console.dir(error);
          setMediaError(error);
        })
        .then(() => {
          getLocalDataTrack(); // 新規
        });
    }
  }, [getAudioAndVideoTracks, getLocalDataTrack, step, mediaError]);

  const { setWhoIs, whoIs, setRmName, rmName } = useAppState();
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      setName(whoIs ?? userName ?? '');
      setRoomName(rmName ?? URLRoomName ?? roomId);
    }
  });
  useEffect(() => {
    if (props.onUpdateRoomAndUser) props.onUpdateRoomAndUser(roomName, name);
    setWhoIs(name);
  }, [name, setWhoIs]);
  useEffect(() => {
    if (props.onUpdateRoomAndUser) props.onUpdateRoomAndUser(roomName, name);
    setRmName(roomName);
  }, [roomName, setRmName]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  if (step === Steps.finishStep) {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      setStep(Steps.roomNameStep);
    }
  }
  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          setStep={setStep}
        />
      )}
    </IntroContainer>
  );
}
