import React, { useRef, useEffect } from 'react';
import h264_avc from '../../../assets/h264_avc.mp4';

const MovieTheater = (props: { play: boolean }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (props.play) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [props.play]);

  return (
    <React.StrictMode>
      <video controls ref={videoRef} style={{ width: 256, height: 144, border: '1px solid #888' }}>
        <source src={h264_avc} type="video/mp4" />
        <p>Your browser doesn&apos;t support HTML5 video.</p>
      </video>
    </React.StrictMode>
  );
};

export default React.memo(MovieTheater);
