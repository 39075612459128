import { useEffect, useRef } from 'react';
import { AudioTrack as IAudioTrack } from 'twilio-video';
import { useAppState } from '../../state';
import { PREVENT_DUPLICATE_AUDIO_TRACKS } from '../../constants';

interface AudioTrackProps {
  track: IAudioTrack;
}

export default function AudioTrack({ track }: AudioTrackProps) {
  const { activeSinkId } = useAppState();
  const audioEl = useRef<HTMLAudioElement>();

  useEffect(() => {
    // Duplicate removal
    if (PREVENT_DUPLICATE_AUDIO_TRACKS) {
      const ATTR = 'data-cy-audio-track-name';
      try {
        const existing = [...document.body.querySelectorAll(`[${ATTR}]`)].filter(
          el => el.getAttribute(ATTR) === track.name
        )[0];
        if (existing) {
          existing.parentElement?.removeChild(existing);
        }
      } catch (err) {
        console.warn(err);
      }
    }

    audioEl.current = track.attach();
    audioEl.current.setAttribute('data-cy-audio-track-name', track.name);
    document.body.appendChild(audioEl.current);
    return () =>
      track.detach().forEach(el => {
        try {
          el.remove();
          // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
          // See: https://github.com/twilio/twilio-video.js/issues/1528
          el.srcObject = null;
        } catch (err) {
          console.warn(err);
        }

        // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
        // See: https://github.com/twilio/twilio-video.js/issues/1528
        el.srcObject = null;
      });
  }, [track]);

  useEffect(() => {
    audioEl.current?.setSinkId?.(activeSinkId);
  }, [activeSinkId]);

  return null;
}
