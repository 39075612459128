import { useState, useCallback, useRef } from 'react';
import { LogLevels, Track, Room } from 'twilio-video';
import { ErrorCallback } from '../../../types';
//import useScreenShare from '../../../hooks/useScreenShare/useScreenShare';
//import { emitCustomEvent } from 'react-custom-events';
import {
  MERGE_CANVAS_INTO_SCREENSHARE,
  SCREENSHARE_CONSTRAINTS,
  SCREENSHARE_TWILIO_TRACK_SETTINGS,
  USE_MULTIPLE_TRACKS_FOR_MERGED_SCREENSHARE_CANVAS,
} from '../../../constants';
import { canvasToStream, streamToVideo, videoToCanvas } from '../../../utility';
import { useAppState } from '../../../state';
import useFullWrappingCaution from '../../../hooks/useFullWrappingCaution/useFullWrappingCaution';
import useStrokeState from '../../../hooks/useStrokeState/useStrokeState';

interface MediaStreamTrackPublishOptions {
  name?: string;
  priority: Track.Priority;
  logLevel: LogLevels;
}

export default function useScreenShareToggle(room: Room | null, onError: ErrorCallback) {
  const [isSharing, setIsSharing] = useState(false);
  const stopScreenShareRef = useRef<() => void>(null!);
  const { setScreenShareStream, setInformation, whoIs /*, setConfirmMessage*/ } = useAppState();
  //const { selectScreenCelemony, setSelectScreenCelemony } = useState(false);
  const { showCaution, hideCaution } = useFullWrappingCaution();
  const { supressClear } = useStrokeState();

  const shareScreen = useCallback(() => {
    // 共有画面選択開始
    showCaution();
    navigator.mediaDevices
      .getDisplayMedia(SCREENSHARE_CONSTRAINTS)
      .then(async stream => {
        // 共有画面選択完了
        hideCaution();
        console.error(stream.getTracks()[0].getSettings());
        let canvasScreenshareMergedStream: MediaStream | undefined;
        if (MERGE_CANVAS_INTO_SCREENSHARE) {
          const mergeCanvasIntoScreenshare = () => {
            const mainCanvas = document.querySelector('[data-canvas-drawing-container-main-canvas]');
            if (!(mainCanvas instanceof HTMLCanvasElement)) {
              console.warn('no canvas');
              return;
            }
            const originalStream = stream; // ストリームイベント同期
            const video = streamToVideo(stream);
            const { canvas } = videoToCanvas(video, {
              onFrame: (c, ctx) => {
                ctx.drawImage(mainCanvas, 0, 0, c.width, c.height);
                return c;
              },
            });
            canvasScreenshareMergedStream = canvasToStream(canvas);
            // ストリームのイベントを同期すべき。
            //

            console.debug('MERGE_CANVAS_INTO_SCREENSHARE', {
              mainCanvas,
              canvas,
              video,
              canvasScreenshareMergedStream,
              originalStream,
            });
          };
          mergeCanvasIntoScreenshare();
        }
        let track = stream.getTracks()[0];
        if (
          /*(track.label === 'screen:0:0' || track.label === 'Primary Monitor') {
          room?.localParticipant.unpublishTrack(track);
          setInformation(
            '全画面共有は間違いなく合わせ鏡現象が発生するため制限しております。\n画面共有を中止しました。'
          );
          return;
        } else if*/ track.label ===
          ''
        ) {
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          if (isSafari) {
            room?.localParticipant.unpublishTrack(track);
            setInformation(
              '全画面共有は間違いなく合わせ鏡現象が発生するため制限しております。\n画面共有を中止しました。'
            );
            return;
          }
        }
        let mergedTrack = canvasScreenshareMergedStream ? canvasScreenshareMergedStream.getTracks()[0] : undefined;
        if (!USE_MULTIPLE_TRACKS_FOR_MERGED_SCREENSHARE_CANVAS) {
          if (mergedTrack) {
            track = mergedTrack;
          }
          mergedTrack = undefined;
        }

        // All video tracks are published with 'low' priority. This works because the video
        // track that is displayed in the 'MainParticipant' component will have it's priority
        // set to 'high' via track.setPriority()
        room?.localParticipant
          .publishTrack(track, SCREENSHARE_TWILIO_TRACK_SETTINGS as MediaStreamTrackPublishOptions)
          .then(trackPublication => {
            let count = 0;
            stopScreenShareRef.current = () => {
              // なぜか何度も呼ばれるため2回目以降をスキップ
              //if (count++ === 0) {
              room.localParticipant.unpublishTrack(track);
              // TODO: remove this if the SDK is updated to emit this event
              room.localParticipant.emit('trackUnpublished', trackPublication);
              track.stop();
              /*const wasSharing = isSharing;
              console.debug({ wasSharing, isSharing });
              if (wasSharing) {
                emitCustomEvent('screenshare-end'); // 事前処理。2回処理にならないよう、確認とステート変更前に実行。
              }
              setScreenShareStream(null);*/
              setIsSharing(false);
              // 描画を削除
              supressClear();

              // ここからが再接続手続き ///////////////////////////////////////////
              // 面談終了ボタンが押されたわけではない場合のみ
              /*if (room?.state === 'connected') {
                  // まず初期画面を隠す
                  hideIntroContainer();
                  // 切断
                  room?.disconnect();
                  // 即座に接続しようとすると音声も映像もミュート状態になることがあるので少し待つ - 100ms
                  setTimeout(() => {
                    // 最初のダイアログ
                    const buttonContinue = document.getElementById('button-continue');
                    if (buttonContinue) {
                      buttonContinue.removeAttribute('disabled');
                      buttonContinue.click();
                    }

                    // joinダイアログ
                    let count = 0;
                    // joinダイアログからstate変数を更新するための接手
                    setUserNameToReconnect(whoIs ?? '');
                    setRoomNameToReconnect(room?.name ?? '');
                    // 以下うまくいくまで100回（10秒）繰り返す
                    const handle2 = setInterval(() => {
                      const buttonJoinNow = document.getElementById('button-join-now') as HTMLButtonElement;
                      if (buttonJoinNow) {
                        // joinボタンからdisabledが抜けたら
                        if (!buttonJoinNow.disabled) {
                          // 繰り返しタイマー停止
                          clearInterval(handle2);
                          // joinボタンをクリック
                          buttonJoinNow.click();
                          // 3秒後に変更を復旧
                          setTimeout(() => {
                            setUserNameToReconnect('');
                            setRoomNameToReconnect('');
                            showIntroContainer();
                          }, 3000); // 3秒後に勝手記憶のuserNameとroomNameを消去
                        }
                      }
                      if (count++ === 100) {
                        // 100回だめならあきらめる
                        clearInterval(handle2);
                        // あきらめた後の画面
                        showIntroContainer();
                      }
                    }, 200);

                    const dummyRoomName = document.getElementById('dummyRoomName');
                    if (dummyRoomName) dummyRoomName.textContent = 'audiotest' ?? '';
                    const buttonJoin = document.getElementById('dummyButtonJoin');
                    buttonJoin?.click();
                  }, 100);
                }*/
              //}
            };
            track.onended = stopScreenShareRef.current;
            setIsSharing(true);
            setScreenShareStream(stream);
          })
          .catch(onError => {
            alert(onError.toString());
          });

        // 複数Track
        if (mergedTrack) {
          room?.localParticipant.publishTrack(mergedTrack, {
            ...SCREENSHARE_TWILIO_TRACK_SETTINGS,
            priority: 'high',
          } as MediaStreamTrackPublishOptions);
          // stop処理を追加
        }
      })
      .catch(error => {
        hideCaution();
        // Don't display an error if the user closes the screen share dialog
        if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
          onError(error);
        }
      });
  }, [room, onError, isSharing]);

  const toggleScreenShare = useCallback(() => {
    if (room) {
      !isSharing ? shareScreen() : stopScreenShareRef.current();
    }
  }, [isSharing, shareScreen, room]);

  return [isSharing, toggleScreenShare] as const;
}
