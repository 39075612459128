import React, { PropsWithChildren, memo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

interface InfoDialogProps {
  information: string | null;
  onClosed: () => void;
}

function InfoDialog({ information, onClosed }: PropsWithChildren<InfoDialogProps>) {
  return (
    <Dialog open={information !== null} onClose={() => onClosed()} fullWidth={true} maxWidth="xs">
      <DialogTitle>Info</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {(information ?? '').split('\n').map(t => (
            <>
              {t}
              <br />
            </>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClosed()} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(InfoDialog);
