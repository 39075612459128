import { useCallback, useEffect, useRef, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';

import { Participant, RemoteTrackPublication, Room, TrackPublication } from 'twilio-video';

function getRoomScreenShareParticipant(room: Room) {
  return (
    Array.from<Participant>(room.participants.values())
      // the screenshare participant could be the localParticipant
      .concat(room.localParticipant)
      .find((participant: Participant) =>
        Array.from<TrackPublication>(participant.tracks.values()).find(track => track.trackName.includes('screen'))
      )
  );
}

/*
  Returns the participant that is sharing their screen (if any). This hook assumes that only one participant
  can share their screen at a time.
*/
export default function useScreenShareParticipant() {
  const { room } = useVideoContext();
  const [screenShareParticipant, setScreenShareParticipant] = useState<Participant>();
  //const [isShowCanvas, setShowCanvas] = useState(false);

  useEffect(() => {
    if (room) {
      const updateScreenShareParticipant = () => {
        setScreenShareParticipant(
          Array.from<Participant>(room.participants.values())
            // the screenshare participant could be the localParticipant
            .concat(room.localParticipant)
            .find((participant: Participant) =>
              Array.from<TrackPublication>(participant.tracks.values()).find(track =>
                track.trackName.includes('screen')
              )
            )
        );
      };
      updateScreenShareParticipant();

      room.on('trackPublished', updateScreenShareParticipant);
      room.on('trackUnpublished', updateScreenShareParticipant);
      room.on('participantDisconnected', updateScreenShareParticipant);

      // the room object does not emit 'trackPublished' events for the localParticipant,
      // so we need to listen for them here.
      room.localParticipant.on('trackPublished', updateScreenShareParticipant);
      room.localParticipant.on('trackUnpublished', updateScreenShareParticipant);
      // return () => {
      //   room.off('trackPublished', updateScreenShareParticipant);
      //   room.off('trackUnpublished', updateScreenShareParticipant);
      //   room.off('participantDisconnected', updateScreenShareParticipant);

      //   room.localParticipant.off('trackPublished', updateScreenShareParticipant);
      //   room.localParticipant.off('trackUnpublished', updateScreenShareParticipant);
      // };
      console.debug('useScreenShareParticipant useEffect');

      room.setMaxListeners(100); // DEBUG用
      room.localParticipant.setMaxListeners(100); // DEBUG用

      // eslint-disable-next-line react-hooks/exhaustive-deps
      // const updateScreenShareParticipant = () => {
      //   if (!room) return;
      //   setScreenShareParticipant(getRoomScreenShareParticipant(room));
      // };

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const onTrackPublished = (publication: RemoteTrackPublication) => {
        console.debug('trackPublished', publication);
        updateScreenShareParticipant();
      };

      const removeEvents = () => {
        // console.debug('useScreenShareParticipant useEffect off')
        // const REMOVE_METHOD = 'off' as const
        const REMOVE_METHOD = 'removeListener' as const;
        room[REMOVE_METHOD]('trackPublished', onTrackPublished); // 同じ
        room[REMOVE_METHOD]('trackUnpublished', updateScreenShareParticipant);
        room[REMOVE_METHOD]('participantDisconnected', updateScreenShareParticipant);

        room.localParticipant[REMOVE_METHOD]('trackPublished', updateScreenShareParticipant);
        room.localParticipant[REMOVE_METHOD]('trackUnpublished', updateScreenShareParticipant);
      };

      const addEvents = () => {
        // console.debug('useScreenShareParticipant useEffect on')
        room.on('trackPublished', onTrackPublished); // 同じ
        room.on('trackUnpublished', updateScreenShareParticipant);
        room.on('participantDisconnected', updateScreenShareParticipant);

        // the room object does not emit 'trackPublished' events for the localParticipant,
        // so we need to listen for them here.
        room.localParticipant.on('trackPublished', updateScreenShareParticipant);
        room.localParticipant.on('trackUnpublished', updateScreenShareParticipant);
      };

      updateScreenShareParticipant();

      removeEvents();
      addEvents();

      return removeEvents;
    }
  }, [room]);

  return screenShareParticipant;
}
