import useVideoContext from '../useVideoContext/useVideoContext';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useParticipants from '../useParticipants/useParticipants';
import useScreenShareParticipant from '../useScreenShareParticipant/useScreenShareParticipant';
import useSelectedParticipant from '../../components/VideoProvider/useSelectedParticipant/useSelectedParticipant';
import { useAppState } from '../../state';
import { useMemo } from 'react';
import { Participant } from 'twilio-video';

export default function useMainParticipant() {
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const dominantSpeaker = useDominantSpeaker();
  const participants = useParticipants();
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const remoteScreenShareParticipant = screenShareParticipant !== localParticipant ? screenShareParticipant : null;
  const { screenShareStream } = useAppState();

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  // return selectedParticipant || remoteScreenShareParticipant || dominantSpeaker || participants[0] || localParticipant;
  const localScreenShareParticipant = screenShareParticipant === localParticipant ? screenShareParticipant : null;
  /*class Participant extends EventEmitter {
    audioTracks: Map<Track.SID, AudioTrackPublication>;
    dataTracks: Map<Track.SID, DataTrackPublication>;
    identity: Participant.Identity;
    networkQualityLevel: NetworkQualityLevel | null;
    networkQualityStats: NetworkQualityStats | null;
    sid: Participant.SID;
    state: string;
    tracks: Map<Track.SID, TrackPublication>;
    videoTracks: Map<Track.SID, VideoTrackPublication>;*/

  const screenShareSettings = screenShareStream?.getTracks()[0].getSettings();
  const isShareFullscreen = useMemo(() => {
    // @ts-ignore
    return screenShareSettings?.displaySurface === 'monitor';
  }, [participants, dominantSpeaker, selectedParticipant, screenShareParticipant, screenShareStream]);

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  // const mainParticipant = selectedParticipant || remoteScreenShareParticipant || dominantSpeaker || participants[0] || localParticipant;
  // TypeErrorにならないように、必ずparticipants[0]を使うこと。
  const mainParticipant =
    remoteScreenShareParticipant ||
    localScreenShareParticipant ||
    selectedParticipant ||
    dominantSpeaker ||
    localParticipant ||
    participants[0]; // screenshare test

  /*return isShareFullscreen
    ? selectedParticipant || remoteScreenShareParticipant || dominantSpeaker || participants[0] || localParticipant
    : mainParticipant;*/
  return { mainParticipant, audioParticipant: dominantSpeaker };
}
