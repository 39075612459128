import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import fileDownload from 'js-file-download';
import AboutDialog from '../../AboutDialog/AboutDialog';
import BackgroundIcon from '../../../icons/BackgroundIcon';
import FileAttachmentIcon from '../../../icons/FileAttachmentIcon';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIconOutlined from '../../../icons/InfoIconOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';
import StartRecordingIcon from '../../../icons/StartRecordingIcon';
import StopRecordingIcon from '../../../icons/StopRecordingIcon';
import SettingsIcon from '../../../icons/SettingsIcon';
import { Button, styled, Theme, useMediaQuery, Menu as MenuContainer, MenuItem, Typography } from '@material-ui/core';
import { isSupported } from '@twilio/video-processors';

import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useIsRecording from '../../../hooks/useIsRecording/useIsRecording';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useFiles from '../../../hooks/useFiles/useFiles';
import FlipCameraIcon from '../../../icons/FlipCameraIcon';
import useFlipCameraToggle from '../../../hooks/useFlipCameraToggle/useFlipCameraToggle';
import FileDownloadIcon from '../../../icons/FileDownloadIcon';

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

export default function ToggleFileButton(props: { buttonClassName?: string }) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const { isFetching, updateRecordingRules, roomType } = useAppState();
  const { setIsChatWindowOpen } = useChatContext();
  const isRecording = useIsRecording();
  const { room, setIsBackgroundSelectionOpen } = useVideoContext();

  const anchorRef = useRef<HTMLButtonElement>(null);
  const files = useFiles();
  const { flipCameraDisabled, toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();
  const downloadFile = async (file: never) => {
    const endpointUrl = process.env.REACT_APP_API_ENDPOINT;
    let url = '';
    let fileName = '';
    const app_id = localStorage.getItem('appId');
    const authorized_key = localStorage.getItem('authorizedKey');
    if (authorized_key && app_id) {
      if (app_id === 'd2d') {
        url = `${endpointUrl}/d2d/consultation-attachments/download?consultation_attachment_alias=${file['consultation_attachment_alias']}`;
        fileName = file['file_name'];
      }
      await axios
        .get(url, { headers: { 'authorized-key': authorized_key, 'app-id': app_id }, responseType: 'blob' })
        .then(res => {
          fileDownload(res.data, fileName);
        });
    }
    // let url = new URL(window.location.href);
    // let params = url.searchParams;
    // const roomId: string | null = params.get('room_id');
    // const nlpApiUrl = process.env.REACT_APP_ENV_NLP_API_HOST;
    //window.open(nlpApiUrl + '/mdp/get-file-twllio/' + file_alias + '/' + recipt_alias);
  };

  return (
    <>
      <Button
        onClick={() => setMenuOpen(isOpen => !isOpen)}
        ref={anchorRef}
        className={props.buttonClassName}
        data-cy-more-button
        startIcon={<FileAttachmentIcon />}
      >
        ファイル一覧
        <ExpandMoreIcon />
      </Button>

      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'bottom',
          horizontal: 'center',
        }}
      >
        {/* {roomType !== 'peer-to-peer' && roomType !== 'go' && (
          <MenuItem
            disabled={isFetching}
            onClick={() => {
              setMenuOpen(false);
              if (isRecording) {
                updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
              } else {
                updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
              }
            }}
            data-cy-recording-button
          >
            <IconContainer>{isRecording ? <StopRecordingIcon /> : <StartRecordingIcon />}</IconContainer>
            <Typography variant="body1">{isRecording ? 'Stop' : 'Start'} Recording</Typography>
          </MenuItem>
        )}
        {flipCameraSupported && (
          <MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode}>
            <IconContainer>
              <FlipCameraIcon />
            </IconContainer>
            <Typography variant="body1">Flip Camera</Typography>
          </MenuItem>
        )} */}

        {/* {files && (
          <MenuItem >
            <IconContainer>
              <SettingsIcon />
            </IconContainer>
            <Typography variant="body1">{files}</Typography>
          </MenuItem>
        )} */}

        {/* {files.map((file) => (
          <MenuItem
            onClick={() => {
              downloadFile(file['file_alias'],file['alias']);
            }}
          >
            <IconContainer>
              <FileDownloadIcon />
            </IconContainer>
            <Typography variant="body1">{file['file_name']}</Typography>
          </MenuItem>
        ))} */}

        {files.length <= 0 ? (
          <MenuItem
            onClick={() => {
              console.log('ファイルがないボタンが押された。');
            }}
          >
            <Typography variant="body1">ファイルがありません</Typography>
          </MenuItem>
        ) : (
          files.map((file, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                downloadFile(file);
              }}
            >
              <IconContainer>
                <FileDownloadIcon />
              </IconContainer>
              <Typography variant="body1">{file['file_name']}</Typography>
            </MenuItem>
          ))
        )}

        {/* <MenuItem onClick={() => setSettingsOpen(true)}>
          <IconContainer>
            <SettingsIcon />
          </IconContainer>
          <Typography variant="body1">Audio and Video Settings</Typography>
        </MenuItem>

        {isSupported && (
          <MenuItem
            onClick={() => {
              setIsBackgroundSelectionOpen(true);
              setIsChatWindowOpen(false);
              setMenuOpen(false);
            }}
          >
            <IconContainer>
              <BackgroundIcon />
            </IconContainer>
            <Typography variant="body1">Backgrounds</Typography>
          </MenuItem>
        )}

        <MenuItem onClick={() => setAboutOpen(true)}>
          <IconContainer>
            <InfoIconOutlined />
          </IconContainer>
          <Typography variant="body1">About</Typography>
        </MenuItem> */}
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
