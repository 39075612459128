import React, { FC, useState, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useStrokeState from '../../hooks/useStrokeState/useStrokeState';
const MARGIN = '4px';

const colors = ['#ff0000', '#ff4000', '#20ff20', '#008000', '#0030ff', '#3000ff', '#ff3080', '#f0f0f0', '#181818'];

export type ColorPickerAttribute = {
  selectedColor: string;
  onChanged: (color: string) => void;
};

const ColorPicker: FC<ColorPickerAttribute> = props => {
  const classes = useStyles();
  const [selected, setSelected] = useState(-1);
  const { setStrokeColor } = useStrokeState();

  // 初期化
  useEffect(() => {
    const qty = colors.length;
    for (let index = 0; index < qty; index++) {
      const color = colors[index];
      if (color === props.selectedColor) {
        setSelected(index);
        break;
      }
    }
  }, [props.selectedColor, setSelected]);

  const onClick = (color: string, index: number) => {
    setStrokeColor(color);
    setSelected(index);
    props.onChanged(color);
  };

  const contents = () => {
    const list: any[] = [];
    colors.forEach((color, index) => {
      if (selected === index) {
        list.push(
          <div className={classes.buttonSelectedOuter} id={`button${index + 1}`}>
            <div
              className={classes.buttonSelectedInner}
              id={`button${index + 1}`}
              style={{ backgroundColor: color }}
            ></div>
          </div>
        );
      } else {
        list.push(
          <div className={classes.buttonOuter} id={`button${index + 1}`}>
            <div
              className={classes.buttonInner}
              id={`button${index + 1}`}
              style={{ backgroundColor: color }}
              onClick={() => onClick(color, index)}
            ></div>
          </div>
        );
      }
    });
    return list;
  };
  return <div className={classes.frame}>{contents()}</div>;
};

const useStyles = makeStyles(() => ({
  frame: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    width: '114px',
    height: '114px',
    margin: MARGIN,
  },
  buttonOuter: {
    width: '30px',
    height: '30px',
    border: '2px solid white',
    borderRadius: '2px',
    margin: MARGIN,
    cursor: 'pointer',
    backgroundColor: 'white',
  },
  buttonInner: {
    width: '26px',
    height: '26px',
    border: '0px solid black',
    borderRadius: '2px',
    margin: '0',
    cursor: 'pointer',
  },
  buttonSelectedOuter: {
    width: '30px',
    height: '30px',
    border: '2px solid white',
    borderRadius: '2px',
    margin: MARGIN,
    cursor: 'pointer',
    filter: 'drop-shadow(0px 2.5px 5px rgba(0, 0, 0, 0.8))',
    backgroundColor: 'white',
  },
  buttonSelectedInner: {
    width: '26px',
    height: '26px',
    border: '0px solid white',
    borderRadius: '2px',
    margin: '0',
    cursor: 'pointer',
  },
}));

export default memo(ColorPicker);
