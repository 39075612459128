import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { Button, styled, Theme, useMediaQuery, Menu as MenuContainer, MenuItem, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CloseIcon from '../../icons/CloseIcon';
import { sendMessage } from '../../customize/modules/httpClient';

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

const useStyles = makeStyles(() =>
  createStyles({
    textarea: {
      border: 'none',
      resize: 'none',
      minWidth: '280px',
      minHeight: '210px',
      fontSize: '14px',
      lineHeight: 1.4,
      '&:focus': {
        outline: 'none',
      },
    },
    completeText: {
      marginRight: '10px',
      '&.hide': {
        opacity: 0,
        visibility: 'hidden',
        transition: '2s',
      },
      '&.show': {
        transition: '2s',
        opacity: 1,
        visibility: 'visible',
      },
    },
    closeChatWindow: {
      cursor: 'pointer',
      background: 'transparent',
      border: '0',
    },
  })
);

export default function MemoWindow() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [memo, setMemo] = useState<string>('');
  const [completeFlg, setCompleteFlg] = useState<boolean>(false);

  const anchorRef = useRef<HTMLButtonElement>(null);
  // メモ保存
  const saveMemo = async () => {
    const app_id = localStorage.getItem('appId') || '';
    const authorized_key = localStorage.getItem('authorizedKey') || '';
    const staff_alias = localStorage.getItem('staffId') || '';
    const room_id = localStorage.getItem('roomName') || '';
    if (authorized_key && app_id) {
      await sendMessage(
        room_id,
        {
          member_alias: staff_alias,
          member_type: 1,
          type: 'memo',
          text: memo,
        },
        authorized_key,
        app_id
      ).then(res => {
        setCompleteFlg(true);
        setTimeout(() => {
          setCompleteFlg(false);
        }, 2000);
      });
    }
  };

  return (
    <>
      <Button
        onClick={() => setMenuOpen(isOpen => !isOpen)}
        ref={anchorRef}
        //className={props.buttonClassName}
        data-cy-more-button
        //startIcon={<FileAttachmentIcon />}
      >
        {menuOpen ? 'メモ非表示' : 'メモ表示'}
        {/* <ExpandMoreIcon /> */}
      </Button>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        //anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -280,
          horizontal: 'right',
        }}
      >
        <div style={{ minWidth: '300px', minHeight: '300px' }}>
          <div style={{ padding: '10px', paddingTop: '0px', borderBottom: '1px solid #ECECEC' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>メモ</div>
              <button className={classes.closeChatWindow} onClick={() => setMenuOpen(isOpen => !isOpen)}>
                <CloseIcon />
              </button>
            </div>
          </div>
          <div style={{ padding: '10px', paddingBottom: '0px' }}>
            <textarea
              className={classes.textarea}
              maxLength={1000}
              value={memo}
              onChange={e => setMemo(e.currentTarget.value)}
              placeholder={'メモを入力'}
            ></textarea>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: '5px' }}>
            <b className={`${classes.completeText} ${completeFlg ? 'show' : 'hide'}`}>保存しました</b>
            <Button onClick={() => saveMemo()}>保存</Button>
          </div>
        </div>
      </MenuContainer>
    </>
  );
}
