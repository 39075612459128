import { useState, useEffect } from 'react';
import axios from 'axios';
import { VideoContext } from '../../components/VideoProvider';

// export default function Files() {
//   console.log('fugaga');
//   const context = useContext(VideoContext);
//   if (!context) {
//     throw new Error('useVideoContext must be used within a VideoProvider');
//   }
//   return context;
// }
export default function useFiles() {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      //let url = new URL(window.location.href);
      //let params = url.searchParams;
      //const roomId: string | null = params.get('room_id');

      // const url = new URL(window.location.href);
      // const pathParts = url.pathname.split('/');
      // if (pathParts.length === 3 && pathParts[1] === 'room') {
      //   const roomId = pathParts[2];
      //   const nlpApiUrl = process.env.REACT_APP_ENV_NLP_API_HOST;
      //   const result = await axios(nlpApiUrl + 'mdp/get-file-names-twllio/' + roomId);
      //   setFiles(result.data.body.data.result);
      // }
      const endpointUrl = process.env.REACT_APP_API_ENDPOINT;
      let url = '';
      const app_id = localStorage.getItem('appId');
      const authorized_key = localStorage.getItem('authorizedKey');
      if (authorized_key && app_id === 'd2d') {
        const reservation_id = localStorage.getItem('serviceAlias');
        url = `${endpointUrl}/d2d/consultation-attachments?reservation_receipt_alias=${reservation_id}`;
        await axios
          .get(url, { headers: { 'authorized-key': authorized_key, 'app-id': app_id } })
          .then(res => setFiles(res.data.consultation_attachments));
      }
    };
    fetchData();
  }, []);

  return files;
}
