import React, { useState, useEffect, useRef } from 'react';
import { interval } from 'd3-timer';

const MeasureNetwork = (props: { valueCB: (value: number) => void }) => {
  const canvasRef = useRef(null);
  const [value, setValue] = useState(0);
  const [calc, setCalc] = useState(0);

  const getContext = (): CanvasRenderingContext2D => {
    const canvas: any = canvasRef.current;
    canvas.width = 24;
    canvas.height = 24;

    return canvas.getContext('2d');
  };

  useEffect(() => {
    const timer = interval(() => {
      get().then(val => {
        // オーバーヘッドタイムを10msとする
        let calc = 10 <= val ? val - 10 : 0;
        props.valueCB(calc);
        calc = Math.sqrt(calc);
        calc = 18 - calc;
        calc = calc < 1 ? 1 : calc;
        setValue(val);
        setCalc(calc);
      });
    }, 1000);
    return () => {
      timer.stop();
    };
  });

  const get = (): Promise<number> => {
    return new Promise((resolve, reject) => {
      const begin = Date.now();
      try {
        fetch('/robots.txt', {
          method: 'GET',
          cache: 'no-store',
        }).then(async res => {
          if (!res.ok) {
            resolve(0);
          } else {
            const end = Date.now();
            resolve(end - begin);
          }
        });
      } catch (e) {
        reject(-1);
      }
    });
  };

  useEffect(() => {
    const ctx: CanvasRenderingContext2D = getContext();
    ctx.beginPath();
    ctx.shadowColor = 'white';
    ctx.rect(0, 0, 24, 24);
    ctx.fillStyle = '#202020';
    ctx.fillRect(1, 1, 22, 22);
    ctx.stroke();
    if (8 < calc) {
      ctx.fillStyle = '#a5e932';
      ctx.fillRect(3, 16, 4, 6);
      ctx.fillRect(10, 10, 4, 12);
      ctx.fillRect(17, 4, 4, 18);
    } else if (3 < calc) {
      ctx.fillStyle = '#ffcc00';
      ctx.fillRect(3, 16, 4, 6);
      ctx.fillRect(10, 10, 4, 12);
    } else {
      ctx.fillStyle = '#ff0000';
      ctx.fillRect(3, 16, 4, 6);
      /*ctx.fillStyle = 'white';
      ctx.fillRect(3 + calc, 3, 18 - calc, 18);*/
    }
    ctx.save();
  }, [calc]);

  return (
    <div>
      <canvas className="canvas" ref={canvasRef} />
      {/*`Echo time: ${value}`*/}
    </div>
  );
};

export default React.memo(MeasureNetwork);
